import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
  FormErrorMessage,
} from "@chakra-ui/react";

import { toast } from "react-hot-toast";
import { Formik, Form, Field } from "formik";

import { useState } from "react";
import { app } from "../redux/api/mongodbHandler";
import { useNavigate } from "react-router-dom";

export default function Forgot() {
  const navigate = useNavigate();

  function validateEmail(value) {
    let error;

    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }

    return error;
  }

  const triggerEmail = async (value) => {
    const email = value.email;
    try {
      await app.emailPasswordAuth.sendResetPasswordEmail({ email });
      toast.success("Email has been sent");
    } catch (errors) {
      toast.error(errors.error);
      throw new Error(errors.error);
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Forgot your Password?</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Formik
            initialValues={{ email: "" }}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                triggerEmail(values);
                actions.setSubmitting(false);
              }, 200);
            }}
          >
            {(props) => (
              <Form>
                <Stack spacing={4}>
                  <Field name="email" validate={validateEmail}>
                    {({ field, form }) => (
                      <FormControl id="emial" isInvalid={props.errors.email}>
                        <FormLabel>E-Mail</FormLabel>
                        <Input {...field} id="email" type="email" />
                        <FormErrorMessage>Email is Required</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Stack spacing={10}>
                    <Button
                      isLoading={props.isSubmitting}
                      type="submit"
                      bg={"tertiary"}
                      color={"black"}
                      _hover={{
                        bg: "primary",
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
}
