import React from "react";
import { Box, Text, VStack } from "@chakra-ui/react";
import { ReactComponent as CatBlack } from "../components/Icons/cat_happy_yellow.svg";

function Loading() {
  return (
    <VStack position={"fixed"} left={"50%"} right={"50%"} bottom={"50%"}>
      <Box pl={5}>
        <CatBlack />
      </Box>
      <Text display={"flex"} justifyContent={"center"} width={"150px"}>
        Chargement ...
      </Text>
    </VStack>
  );
}

export default Loading;
