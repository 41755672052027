import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadAggregatedDataMongoDB } from "../api/mongodbHandler";

export const loadAggregatedData = createAsyncThunk(
  "aggreagtedData/load",
  async () => {
    return loadAggregatedDataMongoDB();
  }
);

export const aggregatedDataSlice = createSlice({
  name: "aggregatedData",
  initialState: {
    status: "idle",
    error: null,
    data: null,
  },
  extraReducers: {
    [loadAggregatedData.pending]: (state) => {
      state.data = null;
      state.status = "laoding";
      state.error = null;
    },
    [loadAggregatedData.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.status = "fetched";
      state.error = null;
    },
    [loadAggregatedData.rejected]: (state, action) => {
      state.data = null;
      state.status = "failed";
      state.error = action.error?.message;
    },
  },
});

export default aggregatedDataSlice.reducer;
