import React from "react";
import {
  HStack,
  Divider,
  Stack,
  Text,
  Center,
  Box,
  useColorModeValue,
  IconButton,
  Button,
} from "@chakra-ui/react";

import { ChevronRightIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useState } from "react";

function CardLayout({
  children,
  title = null,
  expandable = false,
  divider = false,
  width = "400px",
  height = "auto",
  padding = "10px",
  titlePostion = "center",
  textPaddingBottom = "10px",
  titleFontSize = "md",
}) {
  const [isExpanded, setIsExpanded] = useState(!expandable);
  return (
    <Center w={width} py={0} alignSelf="stretch" padding={padding}>
      <Box
        w={"400px"}
        height={height}
        bg={useColorModeValue("white", "gray.900")}
        // boxShadow={"xl"}
        // borderLeft={"1px solid"}
        // borderBottom={"3px solid"}
        // borderRight={"1px solid"}
        // borderColor={"primary.50"}
        rounded={"xl"}
        textAlign={"center"}
        boxShadow={"rgba(0, 0, 0, 0.1) 0px 4px 12px"}
      >
        <HStack justifyContent={titlePostion}>
          {title ? (
            <Text
              lineHeight={"normal"}
              padding={"10px"}
              paddingBottom={textPaddingBottom}
              textAlign={"left"}
              fontWeight={"bold"}
              fontSize={titleFontSize}
            >
              {title}
            </Text>
          ) : null}
          {expandable ? (
            <IconButton
              _focus={{ boxShadow: "none" }}
              aria-label="expand"
              icon={isExpanded ? <ChevronUpIcon /> : <ChevronRightIcon />}
              variant={"outlined"}
              color="black"
              bg="tertiary"
              height={"15px"}
              width={"15px"}
              fontSize={"10px"}
              minWidth={"0px"}
              isRound
              onClick={() => setIsExpanded(!isExpanded)}
            />
          ) : null}
        </HStack>
        <Stack>
          {isExpanded ? (
            <Stack>
              {divider ? (
                <Divider
                  borderColor={"primary.100"}
                  width={"100%"}
                  height={"2px"}
                />
              ) : null}
              {children}
            </Stack>
          ) : null}
        </Stack>
      </Box>
    </Center>
  );
}

export default CardLayout;
