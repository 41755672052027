import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";

ChartJS.register(ArcElement, Tooltip, Legend);

function getData({ todaysData, toCalories, cat }) {
  if (!cat) {
    return toCalories("wet", todaysData.wet_food_planned) -
      toCalories("wet", todaysData.wet_food_eaten) +
      toCalories("dry", todaysData.dry_food_planned) -
      toCalories("dry", todaysData.dry_food_eaten) >
      0
      ? [
          toCalories("dry", todaysData.dry_food_eaten),
          toCalories("wet", todaysData.wet_food_eaten),
          toCalories("wet", todaysData.wet_food_planned) -
            toCalories("wet", todaysData.wet_food_eaten) +
            toCalories("dry", todaysData.dry_food_planned) -
            toCalories("dry", todaysData.dry_food_eaten),
        ]
      : toCalories("wet", todaysData.wet_food_eaten) + toCalories("dry", todaysData.dry_food_eaten)
        > 2 * (toCalories("wet", todaysData.wet_food_planned) + toCalories("dry", todaysData.dry_food_planned)) ? 
        [1,0,0]: [
          toCalories("wet", todaysData.wet_food_planned) -
            toCalories("wet", todaysData.wet_food_eaten) +
            toCalories("dry", todaysData.dry_food_planned) -
            toCalories("dry", todaysData.dry_food_eaten),
          toCalories("dry", todaysData.dry_food_eaten),
          toCalories("wet", todaysData.wet_food_eaten),
        ];
  } else {
    const array = cat.cat_need -
      toCalories("wet", todaysData.wet_food_eaten) -
      toCalories("dry", todaysData.dry_food_eaten) >
      0
      ? [
          toCalories("dry", todaysData.dry_food_eaten),
          toCalories("wet", todaysData.wet_food_eaten),
          cat.cat_need -
            toCalories("wet", todaysData.wet_food_eaten) -
            toCalories("dry", todaysData.dry_food_eaten),
        ]
      : toCalories("wet", todaysData.wet_food_eaten) +
      toCalories("dry", todaysData.dry_food_eaten) > 2 * cat.cat_need ? [1,0,0] : [
          cat.cat_need -
            toCalories("wet", todaysData.wet_food_eaten) -
            toCalories("dry", todaysData.dry_food_eaten),
          toCalories("dry", todaysData.dry_food_eaten),
          toCalories("wet", todaysData.wet_food_eaten),
        ];
      return array
  }
}

function getBackgroundColor({ todaysData, toCalories, cat }) {
  if (!cat) {
    return toCalories("wet", todaysData.wet_food_planned) -
      toCalories("wet", todaysData.wet_food_eaten) +
      toCalories("dry", todaysData.dry_food_planned) -
      toCalories("dry", todaysData.dry_food_eaten) >
      0
      ? ["#FFDED3", "#C6E1FF", "#F9F8F6"]
      : ["#CD4631", "#FFDED3", "#C6E1FF"];
  } else {
    return cat.cat_need -
      toCalories("wet", todaysData.wet_food_eaten) -
      toCalories("dry", todaysData.dry_food_eaten) >
      0
      ? ["#FFDED3", "#C6E1FF", "#F9F8F6"]
      : ["#CD4631", "#FFDED3", "#C6E1FF"];
  }
}

function DayKalories({ todaysData, toCalories, cat }) {
  const size = "130px";
  const data = {
    labels: ["Eaten", "Open"],
    datasets: [
      {
        label: "Food",
        data: getData({ todaysData, toCalories, cat }),
        backgroundColor: getBackgroundColor({ todaysData, toCalories, cat }),

        borderColor: ["#f5f3f0"],
        borderWidth: 2,
      },
    ],
  };
  const options = {
    cutout: "75%",
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <Box position={"relative"}>

      <Doughnut data={data} options={options} height={size} width={size} />
      <VStack
        margin={"auto"}
        position={"absolute"}
        top={"0"}
        left={"0"}
        right={"0"}
        bottom={"0"}
      >
        <Box
          position={"relative"}
          top={"50%"}
          transform={"translateY(-50%)"}
          maxWidth={"80%"}
        >
          <HStack>
            <Text fontWeight={"bold"} fontSize={"md"}>
              {Math.round(
                (toCalories("wet", todaysData.wet_food_eaten) +
                  toCalories("dry", todaysData.dry_food_eaten)) *
                  100
              ) / 100}
              /
            </Text>
            <Text
              fontWeight={"bold"}
              fontSize={"xs"}
              style={{ marginInlineStart: "0px" }}
            >
              {!cat
                ? toCalories("wet", todaysData.wet_food_planned) +
                  toCalories("dry", todaysData.dry_food_planned)
                : cat.cat_need}
            </Text>
          </HStack>
          <Text textStyle={"tertiary"} fontSize={"xs"}>
            KCAL
          </Text>
        </Box>
      </VStack>
    </Box>
  );
}

export default DayKalories;
