import {
  Box,
  FormControl,
  Grid,
  GridItem,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ReactComponent as WetFood } from "../Icons/wet_food.svg";
import { ReactComponent as DryFood } from "../Icons/dry_food.svg";
import React, { useMemo, useState } from "react";
import CardLayout from "../Layout/CardLayout";
import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  calcScheduleManual,
  calcScheduleNormal,
  toCalories,
} from "../Algorithms/CalcSchedule";
import { useEffect } from "react";

function GramSelector({ currentValue, setValue, type }) {
  let neliste = [];
  if (type === "wet") {
    neliste = Array.from({ length: 100 }, (_, i) => i * 20);
  } else {
    neliste = Array.from({ length: 100 }, (_, i) => i * 5);
  }

  return (
    <Box display={"flex"} justifyContent={"center"} width={"60px"}>
      <Select
        borderColor={"black"}
        borderRadius={"xl"}
        fontSize={"xs"}
        height={"25px"}
        width={"100%"}
        fontStyle={"secondary"}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        icon={
          <TriangleDownIcon
            fontSize={12}
            position={"absolute"}
            right={"-4px"}
          />
        }
        style={{ paddingInlineStart: "5px", paddingInlineEnd: "5px" }}
        // icon={<></>}
        defaultValue={currentValue}
      >
        {neliste.map((state) => {
          return (
            <option key={state} value={state}>
              {state} g
            </option>
          );
        })}
      </Select>
    </Box>
  );
}

function FreeFeedingMode({
  cat,
  config,
  setCalcedScheduleGlobal,
  setFreeFeedingModeGlobal,
}) {
  const [ratio, setRatio] = React.useState(0.5);

  useEffect(() => {
    if (
      config?.free_feeding_mode?.daily_wet_credit ||
      config?.free_feeding_mode?.daily_dry_credit
    ) {
      console.log(config?.free_feeding_mode);
      setRatio(
        Number(
          (
            toCalories("wet", config?.free_feeding_mode.daily_wet_credit) /
            (toCalories("wet", config.free_feeding_mode.daily_wet_credit) +
              toCalories("dry", config?.free_feeding_mode.daily_dry_credit))
          ).toFixed(2)
        )
      );
    } else {
      setRatio(0.5);
    }
  }, [config]);

  const [freeFeedingMode, setFreeFeedingMode] = useState(
    config.free_feeding_mode
  );

  const freeFeedingMode_raw = useMemo(() => {
    return config.free_feeding_mode;
  }, [config.free_feeding_mode]);

  useEffect(() => {
    setFreeFeedingMode(freeFeedingMode_raw);
  }, [freeFeedingMode_raw]);

  const [calcedSchedule, setCalcedSchedule] = useState({});

  const dailyWetCredit_raw = useMemo(() => {
    return config?.free_feeding_mode?.daily_wet_credit;
  }, [config?.free_feeding_mode?.daily_wet_credit]);

  const dailyDryCredit_raw = useMemo(() => {
    return config?.free_feeding_mode?.daily_dry_credit;
  }, [config?.free_feeding_mode?.daily_dry_credit]);

  const [dailyWetCredit, setDailyWetCredit] = useState(dailyWetCredit_raw);
  const [dailyDryCredit, setDailyDryCredit] = useState(dailyDryCredit_raw);

  useEffect(() => {
    setDailyWetCredit(dailyWetCredit_raw);
    setDailyDryCredit(dailyDryCredit_raw);
  }, [dailyWetCredit_raw, dailyDryCredit_raw]);

  const changeDailyWetCredit = (e) => {
    setDailyWetCredit(Number(e));
  };

  const changeDailyDryCredit = (e) => {
    setDailyDryCredit(Number(e));
  };

  useEffect(() => {
    //TODO: Calculate Schedule for Schedule and Schedule Manual!
    if (cat && config && ratio !== Infinity && !isManual) {
      setCalcedSchedule(calcScheduleNormal(cat, config, ratio));
    } else if (
      cat &&
      config &&
      isManual &&
      (calcedSchedule.dry_g !== dailyDryCredit ||
        calcedSchedule.wet_g !== dailyWetCredit)
    ) {
      setCalcedSchedule(
        calcScheduleManual(config, dailyWetCredit, dailyDryCredit)
      );
    }
  }, [cat, ratio, dailyWetCredit, dailyDryCredit]);

  const [isManual, setIsManual] = React.useState(
    ratio % 0.25 < 0.09 && ratio <= 1 ? false : true
  );

  useEffect(() => {
    setIsManual(ratio % 0.25 < 0.09 ? false : true);
  }, [ratio]);

  const closestRatio = (ratio) => {
    if (ratio < 0.1) {
      return 0;
    } else if (ratio < 0.37) {
      return 0.25;
    } else if (ratio < 0.63) {
      return 0.5;
    } else if (ratio < 0.89) {
      return 0.75;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    // if (
    //   calcedSchedule.dry_g !== undefined &&
    //   calcedSchedule.wet_g !== undefined
    // ) {
    //   console.log("...");
    //   setDailyDryCredit(calcedSchedule.dry_g);
    //   setDailyWetCredit(calcedSchedule.wet_g);
    // }
    setCalcedScheduleGlobal(calcedSchedule);
    updateFreeFeedingMode();
  }, [calcedSchedule]);

  useEffect(() => {
    setFreeFeedingModeGlobal(freeFeedingMode);
  }, [freeFeedingMode]);

  const updateFreeFeedingMode = () => {
    //TODO: uncomment this line
    if (calcedSchedule.dry_g >= 0 && calcedSchedule.wet_g >= 0) {
      if (
        calcedSchedule.dry_g !== freeFeedingMode?.daily_dry_credit ||
        calcedSchedule.wet_g !== freeFeedingMode?.daily_wet_credit
      ) {
        let tmp = { ...freeFeedingMode };
        tmp.daily_dry_credit = calcedSchedule.dry_g;
        tmp.daily_wet_credit = calcedSchedule.wet_g;
        setFreeFeedingMode(tmp);
      }
    }
  };

  return (
    <CardLayout
      title={"Mode d’alimentation de " + cat?.cat_name}
      width={"100%"}
    >
      <VStack position={"relative"} spacing={5} marginBottom={"20px"}>
        <Box>
          <FormControl id="cat_race">
            <Select
              id="cat_race"
              borderColor={"black"}
              borderRadius={"xl"}
              fontSize={"xs"}
              value={!isManual ? closestRatio(ratio) : "Manuel"}
              height={"25px"}
              width={"fit-content"}
              fontStyle={"secondary"}
              onChange={(e) => {
                if (e.target.value === "Manuel") {
                  //Manuel Mode
                  setIsManual(true);
                } else {
                  setIsManual(false);
                  setRatio(e.target.value);
                }
              }}
              icon={<TriangleDownIcon fontSize={12} />}
            >
              <option value={0.0}>0% Pâtée - 100% Croquettes</option>
              <option value={0.25}>25% Pâtée - 75% Croquettes</option>
              <option value={0.5}>50% Pâtée - 50% Croquettes</option>
              <option value={0.75}>75% Pâtée - 25% Croquettes</option>
              <option value={1.0}>100% Pâtée - 0% Croquettes</option>
              <option value={"Manuel"}>Mode Manuel</option>
            </Select>
          </FormControl>
        </Box>
        <VStack
          margin={"10px"}
          position={"relative"}
          width={"90%"}
          backgroundColor={"primary.50"}
          borderRadius={"2xl"}
          paddingBottom={"xl"}
        >
          <Grid
            templateColumns={"30% 5% 30% 5% 30%"}
            width={"100%"}
            paddingTop={"10px"}
            paddingBottom={"10px"}
            gap
            alignContent={"center"}
            gridAutoFlow={"row"}
            alignItems={"center"}
          >
            <GridItem display={"flex"} justifyContent={"center"}>
              <WetFood height={"50px"} stroke="black" />
            </GridItem>
            <GridItem>
              <Text fontSize={"30px"}>+</Text>
            </GridItem>
            <GridItem display={"flex"} justifyContent={"center"}>
              <DryFood height={"20px"} stroke="black" />
            </GridItem>
            <GridItem>
              <Text fontSize={"30px"}>=</Text>
            </GridItem>
            <GridItem
              display={"flex"}
              justifyContent={"center"}
              alignSelf={"center"}
            >
              <Text fontSize={"30px"}>{calcedSchedule.schedule?.length}</Text>
            </GridItem>
            <GridItem>
              <Text marginTop={"5px"} textStyle={"secondary"} fontSize={"xs"}>
                PÂTÉE
              </Text>
            </GridItem>
            <GridItem></GridItem>
            <GridItem>
              <Text marginTop={"5px"} fontSize={"xs"} textStyle={"secondary"}>
                CROQUETTES
              </Text>
            </GridItem>{" "}
            <GridItem></GridItem>
            <GridItem>
              <Text marginTop={"5px"} fontSize={"xs"} textStyle={"secondary"}>
                REPAS
              </Text>
            </GridItem>{" "}
            <GridItem justifyContent={"center"} display={"flex"}>
              {isManual ? (
                <GramSelector
                  currentValue={dailyWetCredit}
                  setValue={changeDailyWetCredit}
                  type={"wet"}
                />
              ) : (
                <Text fontWeight={"bold"} fontSize={"13px"}>
                  {calcedSchedule.wet_g} g
                </Text>
              )}
            </GridItem>{" "}
            <GridItem></GridItem>
            <GridItem justifyContent={"center"} display={"flex"}>
              {isManual ? (
                <GramSelector
                  currentValue={dailyDryCredit}
                  setValue={changeDailyDryCredit}
                  type={"dry"}
                />
              ) : (
                <Text fontWeight={"bold"} fontSize={"13px"}>
                  {calcedSchedule.dry_g} g
                </Text>
              )}
            </GridItem>{" "}
            <GridItem>
              <Text fontWeight={"bold"} fontSize={"13px"}></Text>
            </GridItem>
            <GridItem>
              <Text fontWeight={"bold"} fontSize={"13px"}>
                {calcedSchedule.dry_g + calcedSchedule.wet_g} g
              </Text>
            </GridItem>
            <GridItem>
              <Text fontWeight={"bold"} fontSize={"13px"}>
                {toCalories("wet", calcedSchedule.wet_g)} Kcal
              </Text>
            </GridItem>
            <GridItem>
              <Text fontWeight={"bold"} fontSize={"13px"}></Text>
            </GridItem>
            <GridItem>
              <Text fontWeight={"bold"} fontSize={"13px"}>
                {toCalories("dry", calcedSchedule.dry_g)} Kcal
              </Text>
            </GridItem>
            <GridItem>
              <Text fontWeight={"bold"} fontSize={"13px"}></Text>
            </GridItem>
            <GridItem>
              <Text fontWeight={"bold"} fontSize={"13px"}>
                {toCalories("wet", calcedSchedule.wet_g) +
                  toCalories("dry", calcedSchedule.dry_g)}{" "}
                Kcal
              </Text>
            </GridItem>
            <Box>
              <VStack>
                <Text></Text>
              </VStack>
            </Box>
            <Box>
              <VStack>
                <Text></Text>
              </VStack>
            </Box>
          </Grid>
        </VStack>
      </VStack>
    </CardLayout>
  );
}

export default FreeFeedingMode;
