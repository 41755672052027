import { Box, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import React from "react";
import CardLayout from "../Layout/CardLayout";
import Placeholder from "./Placeholder";
import { ReactComponent as WetFood } from "../Icons/wet_food.svg";
import { ReactComponent as DryFood } from "../Icons/dry_food.svg";
import { formattedTime } from "../../pages/Home";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";

function ScheduleItem({ item, id, config, old }) {
  let type = "upcomming";
  if (item.time < formattedTime()) {
    if (item.delivered_today) {
      type = "delivered";
    } else {
      type = "missed";
    }
  }

  return (
    <Grid
      width={"90%"}
      border={"solid 1px"}
      borderRadius={"5px"}
      borderColor={
        type === "upcomming" || old
          ? "black"
          : type === "delivered"
          ? "gray.300"
          : "red.300"
      }
      padding={"5px"}
      templateColumns="15px 1fr auto"
      gap={2}
    >
      <>
        {/* TODO: HotFix for Datastructure switch of Schedule */}
        {item.todo === undefined ? (
          <>
            <GridItem w="20px" alignSelf={"center"}>
              {type === "upcomming" || old ? (
                item.active ? (
                  <WetFood height={"15px"} width={"20px"} stroke={"black"} />
                ) : (
                  <DryFood height={"15px"} width={"20px"} stroke={"black"} />
                )
              ) : type === "delivered" ? (
                <CheckCircleIcon color={"green.300"} />
              ) : (
                <WarningIcon color={"red.300"} />
              )}
            </GridItem>
            <GridItem w="170px" display={"flex"} justifyContent={"flex-start"}>
              {item.active ? (
                <Text>pâtée ({config.wet_weight_by_dose}g) </Text>
              ) : (
                <Text>croquettes ({config.dry_weight_by_dose}g)</Text>
              )}
            </GridItem>
          </>
        ) : (
          <>
            <GridItem w="20px" alignSelf={"center"}>
              {type === "upcomming" || old ? (
                item.todo[0] === "wet" ? (
                  <WetFood height={"15px"} width={"20px"} stroke={"black"} />
                ) : (
                  <DryFood height={"15px"} width={"20px"} stroke={"black"} />
                )
              ) : type === "delivered" ? (
                <CheckCircleIcon color={"green.300"} />
              ) : (
                <WarningIcon color={"red.300"} />
              )}
            </GridItem>
            <GridItem w="170px" display={"flex"} justifyContent={"flex-start"}>
              {item.todo[0] === "wet" ? (
                <Text>
                  pâtée ({config.wet_weight_by_dose * item.todo.length}g){" "}
                </Text>
              ) : (
                <Text>
                  croquettes ({config.dry_weight_by_dose * item.todo.length}g)
                </Text>
              )}
            </GridItem>
          </>
        )}
      </>
      <GridItem>
        <Text>{item.time}</Text>
      </GridItem>
    </Grid>
  );
}

function TodaySchedule({ todaysData, config }) {
  return (
    <CardLayout title={"Menu du jour"} divider={true} width={"100%"}>
      <Box>
        {todaysData?.schedule ? (
          <VStack
            marginBottom={"10px"}
            overflowY={"scroll"}
            maxHeight={"150px"}
            textStyle={"secondary"}
          >
            {todaysData.schedule.map((item, index) => {
              return (
                <ScheduleItem
                  item={item}
                  id={index}
                  config={config}
                  old={todaysData.old}
                  key={index}
                />
              );
            })}
          </VStack>
        ) : (
          <Placeholder />
        )}
      </Box>
    </CardLayout>
  );
}

export default TodaySchedule;
