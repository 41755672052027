import React, { useEffect, useMemo, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  VStack,
  HStack,
  Box,
  Button,
  useDisclosure,
  IconButton,
  Container,
  Text,
} from "@chakra-ui/react";
import Needed from "../components/Graphs/Needed";
import Schedule from "../components/Cards/Schedule";
import { useDispatch, useSelector } from "react-redux";
import { loadConfig, updateConfig } from "../redux/slices/configSlice";
import { AddIcon } from "@chakra-ui/icons";
import { formattedTime } from "./Home";
import CardLayout from "../components/Layout/CardLayout";
import CurrentSchedule from "../components/Cards/CurrentSchedule";
import { ReactComponent as Time } from "../components/Icons/time_cat.svg";
import TimePicker from "react-time-picker";
import { TriangleDownIcon } from "@chakra-ui/icons";
import ScheduleModal from "../components/Modals/ScheduleModal";

function Settings() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  let freefeeding = state.config.data
    ? state.config.data?.free_feeding_mode
    : {};
  let schedule = state.config.data ? [...state.config.data?.schedules] : [];
  let mode = state.config.data ? state.config.data?.mode : "schedule";

  const [startMorning, onChangeStartMorning] = useState("");
  const [endMorning, onChangeEndMorning] = useState("");
  const [startAfternoon, onChangeStartAfternoon] = useState("");
  const [endAfternoon, onChangeEndAfternoon] = useState("");

  const cat = state.catprofile.data;

  useEffect(() => {
    if (freefeeding.active_windows && freefeeding.active_windows.length > 0) {
      onChangeStartMorning(freefeeding.active_windows[0].begin);
      onChangeEndMorning(freefeeding.active_windows[0].end);
      onChangeStartAfternoon(freefeeding.active_windows[1].begin);
      onChangeEndAfternoon(freefeeding.active_windows[1].end);
    }
  }, [freefeeding]);

  const schedule_sorted = useMemo(() => {
    let tmp = [...schedule];
    tmp.sort((a, b) => a.time.replace(":", "") - b.time.replace(":", ""));
    return tmp;
  }, [schedule]);

  const handleHour = () => {
    dispatch(
      updateConfig({
        "free_feeding_mode.active_windows": [
          {
            begin: startMorning,
            end: endMorning,
          },
          {
            begin: startAfternoon,
            end: endAfternoon,
          },
        ],
        timestamp: new Date(),
      })
    );
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);

  return (
    <Layout>
      <VStack>
        {mode === "schedule" ? (
          <>
            <Box maxW={"400px"} width={"95%"}>
              {schedule && cat ? (
                <CurrentSchedule
                  config={state.config.data}
                  schedule={schedule}
                  cat={cat}
                />
              ) : null}
              <Box paddingTop={"240"}>
                <CardLayout width="100%">
                  <HStack padding={5}>
                    <Time />
                    <VStack align={"flex-start"} pl={2}>
                      <Text> Horaires de distribution</Text>
                      <Text
                        textStyle={"tertiary"}
                        fontSize={"xs"}
                        color="text" //primary.500 Wet
                        textAlign={"left"}
                      >
                        HORAIRES FIXES
                      </Text>
                    </VStack>
                  </HStack>
                </CardLayout>
              </Box>
              <Schedule
                gap={cat ? true : false}
                schedule={schedule_sorted}
                updateConfig={updateConfig}
              />
            </Box>
            <IconButton
              top={"-20px"}
              isRound
              bg={"tertiary"}
              icon={<AddIcon />}
              onClick={() => {
                dispatch(
                  updateConfig({
                    schedules: [
                      ...schedule,
                      { time: formattedTime(), todo: ["wet"] },
                    ],
                  })
                );
              }}
            />
          </>
        ) : (
          <>
            <Box width="95%" maxW="400px">
              {schedule && cat ? (
                <CurrentSchedule
                  config={state.config.data}
                  schedule={schedule}
                  cat={cat}
                />
              ) : null}
              <Box paddingTop={"250"}>
                <CardLayout width="100%">
                  <HStack padding={5}>
                    <Time />
                    <VStack align={"flex-start"} pl={2}>
                      <Text> Horaires de distribution</Text>
                      <Text
                        textStyle={"tertiary"}
                        fontSize={"xs"}
                        color="text" //primary.500 Wet
                        textAlign={"left"}
                      >
                        {cat?.cat_name.toUpperCase()} CHOISIT SES HORAIRES
                      </Text>
                    </VStack>
                  </HStack>
                </CardLayout>
              </Box>
              <Box>
                <Box padding={0}>
                  <CardLayout width="100%">
                    <VStack>
                      <Box
                        flexDirection={"column"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                        px={5}
                        pt={5}
                        mb={2}
                      >
                        <Text fontSize={"14px"}>50% des ses repas entre</Text>
                        <Box
                          flexDirection={"row"}
                          display={"flex"}
                          justifyContent={"center"}
                          pt={1}
                        >
                          <Box
                            borderColor={"black"}
                            borderRadius={"xl"}
                            display="flex"
                            justifyContent={"center"}
                            borderWidth={"1px"}
                            px={4}
                            onClick={() => {
                              setIsOpen(true);
                              setSelectedRange({
                                time: startMorning,
                                callback: onChangeStartMorning,
                              });
                            }}
                          >
                            <Text fontSize={"14px"}>{startMorning}</Text>
                          </Box>
                          <Text fontSize={"14px"} mx={3}>
                            ET
                          </Text>
                          <Box
                            borderColor={"black"}
                            borderRadius={"xl"}
                            display="flex"
                            justifyContent={"center"}
                            borderWidth={"1px"}
                            px={4}
                            onClick={() => {
                              setIsOpen(true);
                              setSelectedRange({
                                time: endMorning,
                                callback: onChangeEndMorning,
                              });
                            }}
                          >
                            <Text fontSize={"14px"}>{endMorning}</Text>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        flexDirection={"column"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                        px={5}
                        pb={5}
                      >
                        <Text fontSize={"14px"}>50% des ses repas entre </Text>
                        <Box
                          flexDirection={"row"}
                          justifyContent={"center"}
                          display={"flex"}
                          pt={1}
                        >
                          <Box
                            borderColor={"black"}
                            borderRadius={"xl"}
                            display="flex"
                            justifyContent={"center"}
                            borderWidth={"1px"}
                            px={4}
                            onClick={() => {
                              setIsOpen(true);
                              setSelectedRange({
                                time: startAfternoon,
                                callback: onChangeStartAfternoon,
                              });
                            }}
                          >
                            <Text fontSize={"14px"}>{startAfternoon}</Text>
                          </Box>
                          <Text fontSize={"14px"} mx={3}>
                            ET
                          </Text>
                          <Box
                            borderColor={"black"}
                            borderRadius={"xl"}
                            display="flex"
                            justifyContent={"center"}
                            borderWidth={"1px"}
                            px={4}
                            onClick={() => {
                              setIsOpen(true);
                              setSelectedRange({
                                time: endAfternoon,
                                callback: onChangeEndAfternoon,
                              });
                            }}
                          >
                            <Text fontSize={"14px"}>{endAfternoon}</Text>
                          </Box>
                        </Box>
                      </Box>
                    </VStack>
                  </CardLayout>
                </Box>
              </Box>
              <Box display={"flex"} justifyContent="center">
                <Button
                  type="submit"
                  bg={"tertiary"}
                  color={"black"}
                  onClick={handleHour}
                  _hover={{
                    bg: "primary",
                  }}
                  isDisabled={
                    startAfternoon === "" ||
                    endAfternoon === "" ||
                    startMorning === "" ||
                    endMorning === "" ||
                    startAfternoon === null ||
                    endAfternoon === null ||
                    startMorning === null ||
                    endMorning === null
                  }
                >
                  APPLIQUER
                </Button>
              </Box>
            </Box>
            <ScheduleModal
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              item={selectedRange}
              changeTime={(item, time) =>
                selectedRange?.callback(formattedTime(time))
              }
            />
          </>
        )}
        <Container height={"40px"}></Container>
      </VStack>
    </Layout>
  );
}

export default Settings;
