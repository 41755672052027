import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button,
  ModalFooter,
  useDisclosure,
  Text,
  Container,
  VStack,
  SimpleGrid,
} from "@chakra-ui/react";
import { ReactComponent as Cat1 } from "../Icons/1.svg";
import { ReactComponent as Cat2 } from "../Icons/2.svg";
import { ReactComponent as Cat3 } from "../Icons/3.svg";
import { ReactComponent as Cat4 } from "../Icons/4.svg";
import { ReactComponent as Cat5 } from "../Icons/5.svg";
import { ReactComponent as Cat6 } from "../Icons/6.svg";
import { ReactComponent as Cat7 } from "../Icons/7.svg";
import { ReactComponent as Cat8 } from "../Icons/8.svg";
import { ReactComponent as Cat9 } from "../Icons/9.svg";
import { ReactComponent as Cat10 } from "../Icons/10.svg";
import { ReactComponent as Cat11 } from "../Icons/11.svg";

import { ReactComponent as Color1 } from "../Icons/color_1.svg";
import { ReactComponent as Color2 } from "../Icons/color_2.svg";
import { ReactComponent as Color3 } from "../Icons/color_3.svg";
import { ReactComponent as Color4 } from "../Icons/color_4.svg";
import { ReactComponent as Color5 } from "../Icons/color_5.svg";
// import { ReactComponent as Color6} from "../Icons/color_6.svg";
import { ReactComponent as Color7 } from "../Icons/color_7.svg";
import { ReactComponent as Color8 } from "../Icons/color_8.svg";
import { ReactComponent as Color9 } from "../Icons/color_9.svg";
import { ReactComponent as Color10 } from "../Icons/color_10.svg";
import { ReactComponent as Color11 } from "../Icons/color_11.svg";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../redux/slices/configSlice";
import { updateCatProfile } from "../../redux/slices/catProfileSlice";

function CatIcon({ icon, display }) {
  const height = display ? "65px" : "100%";
  const width = display ? "inherit" : "100%";

  switch (icon) {
    case "1":
      return <Cat1 height={height} padding={"4px"} width={width} />;
    case "2":
      return <Cat2 height={height} padding={"4px"} width={width} />;
    case "3":
      return <Cat3 height={height} padding={"4px"} width={width} />;
    case "4":
      return <Cat4 height={height} padding={"4px"} width={width} />;
    case "5":
      return <Cat5 height={height} padding={"4px"} width={width} />;
    case "6":
      return <Cat6 height={height} padding={"4px"} width={width} />;
    case "7":
      return <Cat7 height={height} padding={"4px"} width={width} />;
    case "8":
      return <Cat8 height={height} padding={"4px"} width={width} />;
    case "9":
      return <Cat9 height={height} padding={"4px"} width={width} />;
    case "10":
      return <Cat10 height={height} padding={"4px"} width={width} />;
    case "11":
      return <Cat11 height={height} padding={"4px"} width={width} />;
    default:
      return <Cat1 height={height} padding={"4px"} width={width} />;
  }
}

function ChooseCatModal({ cat }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [icon, setIcon] = useState(cat?.icon);

  const dispatch = useDispatch();

  if (cat) {
    return (
      <>
        <div onClick={onOpen} style={{marginTop: -40}} >
          <CatIcon icon={cat?.icon} display={true} />
        </div>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={10}>
                <Text>Choisissez son avatar</Text>
                <Container
                  backgroundColor={"primary.50"}
                  rounded={"xl"}
                  textAlign={"center"}
                  boxShadow={"rgba(0, 0, 0, 0.1) 0px 4px 12px"}
                  display={"flex"}
                  justifyContent={"center"}
                  width={"200px"}
                  height={"200px"}
                >
                  <VStack width={"70%"}>
                    <CatIcon icon={icon} display={false} />
                  </VStack>
                </Container>
                <Container display={"flex"} justifyContent={"center"}>
                  <SimpleGrid
                    templateColumns={"1fr 1fr 1fr 1fr 1fr"}
                    rowGap={2}
                    columnGap={2}
                    columns={5}
                    width={"fit-content"}
                  >
                    <div
                      onClick={() => {
                        setIcon("1");
                      }}
                    >
                      <Color1 />
                    </div>
                    <div
                      onClick={() => {
                        setIcon("2");
                      }}
                    >
                      <Color2 />
                    </div>
                    <div
                      onClick={() => {
                        setIcon("3");
                      }}
                    >
                      <Color3 />
                    </div>
                    <div
                      onClick={() => {
                        setIcon("4");
                      }}
                    >
                      <Color4 />
                    </div>
                    <div
                      onClick={() => {
                        setIcon("5");
                      }}
                    >
                      <Color5 />
                    </div>
                    <div
                      onClick={() => {
                        setIcon("7");
                      }}
                    >
                      <Color7 />
                    </div>
                    <div
                      onClick={() => {
                        setIcon("8");
                      }}
                    >
                      <Color8 />
                    </div>
                    <div
                      onClick={() => {
                        setIcon("9");
                      }}
                    >
                      <Color9 />
                    </div>
                    <div
                      onClick={() => {
                        setIcon("10");
                      }}
                    >
                      <Color10 />
                    </div>
                    <div
                      onClick={() => {
                        setIcon("11");
                      }}
                    >
                      <Color11 />
                    </div>
                  </SimpleGrid>
                </Container>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
                bg={"tertiary"}
                fontSize={"xs"}
                onClick={() => {
                  let tmp = { ...cat };
                  tmp.icon = icon;
                  dispatch(updateCatProfile(tmp));
                  onClose();
                }}
                textStyle={"tertiary"}
                boxShadow={"rgba(0, 0, 0, 0.1) 0px 4px 12px"}
              >
                APPLIQUER
              </Button>{" "}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
}

export default ChooseCatModal;
