import React from "react";
import { formattedTime } from "../../pages/Home";

export const toCalories = (type, value) => {
  if (type === "wet") {
    return value * 1;
  } else {
    return value * 4;
  }
};

export function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

function alternate(arr) {
  console.log(arr);
  const rearrangedArr = [...arr];
  const A = arr[0];
  let B;
  let nextIndexToSwap = 1;
  let hasB = arr.findIndex((el) => el !== A);
  if (hasB) {
    B = arr[hasB];
    // Let's go
    while (hasB !== -1 && nextIndexToSwap < arr.length) {
      // Swap
      rearrangedArr[nextIndexToSwap] = B;
      rearrangedArr[hasB] = A;
      // Next one
      nextIndexToSwap = nextIndexToSwap + 2;
      hasB = rearrangedArr.findIndex(
        (el, i) => el === B && i > nextIndexToSwap
      );
      console.log(hasB);
    }
  }

  return rearrangedArr;
}

function verifyScheduleKcal(schedule, targetDry, targetWet) {
  const allDoses = schedule.map((s) => s.todo).flat();
  const wetDoses = allDoses.filter((d) => d === "wet").length;
  const dryDoses = allDoses.filter((d) => d === "dry").length;

  return {
    ok:
      (wetDoses, dryDoses, wetDoses * 20 + dryDoses * 20) ===
      targetDry + targetWet,
    wet: targetWet - wetDoses * 20,
    dry: targetDry * 4 - dryDoses * 20,
  };
} // TODO: take config.dry_weight_by_dose values

export function calcScheduleNormal(cat, config, wet_ratio) {
  // Wet food
  const wet_kcal = Math.round(
    Math.round(
      (cat.cat_need / toCalories("wet", config.wet_weight_by_dose)) * wet_ratio
    ) * toCalories("wet", config.wet_weight_by_dose)
  );
  let wet_g = Math.round(wet_kcal / toCalories("wet", 1));
  let wet_doses = Math.round(wet_g / config.wet_weight_by_dose);

  // Dry food
  const dry_kcal = Math.round(
    Math.round(
      (cat.cat_need / toCalories("dry", config.dry_weight_by_dose)) *
        (1 - wet_ratio)
    ) * toCalories("dry", config.dry_weight_by_dose)
  );
  let dry_g = Math.round(dry_kcal / toCalories("dry", 1));
  let dry_doses = Math.round(dry_g / config.dry_weight_by_dose);

  if (cat.cat_need - wet_kcal - dry_kcal > 10) {
    dry_doses += 1;
    dry_g += config.dry_weight_by_dose;
  } else if (cat.cat_need - wet_kcal - dry_kcal < -10) {
    dry_doses -= 1;
    dry_g -= config.dry_weight_by_dose;
  }

  const repas = dry_doses + wet_doses;
  const timerange =
    (new Date(
      "Mon May 30 2022 20:00:00 GMT+0200 (Mitteleuropäische Sommerzeit)"
    ).getTime() -
      new Date(
        "Mon May 30 2022 08:00:00 GMT+0200 (Mitteleuropäische Sommerzeit)"
      ).getTime()) /
    10;

  let doses = Array.from({ length: dry_doses }, (_, i) => "dry").concat(
    Array.from({ length: wet_doses }, (_, i) => "wet")
  );

  if (!doses.length) {
    return {
      schedule: [],
      wet_g: wet_g,
      dry_g: dry_g,
      repas: repas,
    };
  }

  const maxLength = (type) => (type === "dry" ? 2 : 2);

  let groupedDoses = [];
  for (let i = 0; i < doses.length; i++) {
    // if (groupedDoses.length < 10) {
    //   groupedDoses.push([doses[i]]);
    // } else {
    // We fill each slot up to 2 doses
    const index = groupedDoses.findIndex(
      (d) => d?.[0] === doses[i] && d?.length < 2
    );

    if (index === -1) {
      // We fill each slot up to MAX doses
      const indexWithMaxDose = groupedDoses.findIndex(
        (d) => d?.[0] === doses[i] && d?.length < maxLength(doses[i])
      );
      if (indexWithMaxDose === -1) {
        groupedDoses.push([doses[i]]);
      } else {
        groupedDoses[indexWithMaxDose].push(doses[i]);
      }
    } else {
      groupedDoses[index].push(doses[i]);
    }
    // }
  }

  let indexStart = 0;
  while (groupedDoses.length < 10 && indexStart < 12) {
    const pushToEnd = groupedDoses[indexStart].pop();
    groupedDoses.push([pushToEnd]);
    indexStart++;
  }

  if (dry_doses > 0) {
    while (groupedDoses[groupedDoses.length - 1][0] !== "dry") {
      groupedDoses = shuffle(groupedDoses);
    }
  }

  let schedule = Array.from({ length: groupedDoses.length }, (_, i) => ({
    time: formattedTime(
      new Date(
        "Mon May 30 2022 08:00:00 GMT+0200 (Mitteleuropäische Sommerzeit)"
      ).getTime() +
        timerange * i
    ),
    todo: groupedDoses.pop(),
  }));

  return {
    schedule: schedule,
    wet_g: wet_g,
    dry_g: dry_g,
    repas: repas,
  };
}

export function calcScheduleManual(config, wet_g, dry_g) {
  const dry_doses = Math.round(dry_g / config.dry_weight_by_dose);
  const wet_doses = Math.round(wet_g / config.wet_weight_by_dose);

  const repas = dry_doses + wet_doses;
  const timerange =
    (new Date(
      "Mon May 30 2022 20:00:00 GMT+0200 (Mitteleuropäische Sommerzeit)"
    ).getTime() -
      new Date(
        "Mon May 30 2022 08:00:00 GMT+0200 (Mitteleuropäische Sommerzeit)"
      ).getTime()) /
    10;

  let doses = Array.from({ length: dry_doses }, (_, i) => "dry").concat(
    Array.from({ length: wet_doses }, (_, i) => "wet")
  );

  const maxLength = (type) => (type === "dry" ? 2 : 2);

  let groupedDoses = [];
  for (let i = 0; i < doses.length; i++) {
    // if (groupedDoses.length < 10) {
    //   groupedDoses.push([doses[i]]);
    // } else {
    // We fill each slot up to 2 doses
    const index = groupedDoses.findIndex(
      (d) => d?.[0] === doses[i] && d?.length < 2
    );

    if (index === -1) {
      // We fill each slot up to MAX doses
      const indexWithMaxDose = groupedDoses.findIndex(
        (d) => d?.[0] === doses[i] && d?.length < maxLength(doses[i])
      );
      if (indexWithMaxDose === -1) {
        groupedDoses.push([doses[i]]);
      } else {
        groupedDoses[indexWithMaxDose].push(doses[i]);
      }
    } else {
      groupedDoses[index].push(doses[i]);
    }
    // }
  }

  console.log(groupedDoses);

  let indexStart = 0;
  while (groupedDoses.length < 10 && indexStart < 12) {
    const pushToEnd = groupedDoses[indexStart].pop();
    groupedDoses.push([pushToEnd]);
    indexStart++;
  }

  if (dry_doses > 0) {
    while (groupedDoses[groupedDoses.length - 1][0] !== "dry") {
      groupedDoses = shuffle(groupedDoses);
    }
  }

  let schedule = Array.from({ length: groupedDoses.length }, (_, i) => ({
    time: formattedTime(
      new Date(
        "Mon May 30 2022 08:00:00 GMT+0200 (Mitteleuropäische Sommerzeit)"
      ).getTime() +
        timerange * i
    ),
    todo: groupedDoses.pop(),
  }));

  // for (; doses.length > 0; ) {
  //   const rand_food = doses.pop();
  //   //filter schedule by todo
  //   let filtered = schedule.filter((item) => item.todo[0] === rand_food);

  //   let i = 0;
  //   let forbidden = true;

  //   while (i < 5 && forbidden) {
  //     i++;

  //     let tbc = filtered[Math.floor(Math.random() * filtered.length)];
  //     let item_index = schedule.findIndex((item) => tbc === item);

  //     if (item_index !== -1) {
  //       if (rand_food === "wet") {
  //         // WET FOOD MAX 2 DOSES
  //         if (schedule[item_index].todo.length < 2) {
  //           forbidden = false;
  //           schedule[item_index].todo.push(rand_food);
  //         }
  //       } else {
  //         // DRY FOOD MAX 3 DOSES
  //         if (schedule[item_index].todo.length < 3) {
  //           forbidden = false;
  //           schedule[item_index].todo.push(rand_food);
  //         }
  //       }
  //     }
  //   }
  // }

  return {
    schedule: schedule,
    wet_g: wet_g,
    dry_g: dry_g,
    repas: repas,
  };
}
