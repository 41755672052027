import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
  FormErrorMessage,
} from "@chakra-ui/react";
import {
  setClientMongoDB,
} from "../redux/api/mongodbHandler";
import { toast } from "react-hot-toast";
import { Formik, Form, Field } from "formik";
import { loadCatProfile } from "../redux/slices/catProfileSlice";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/slices/userSlice";
import { app } from "../redux/api/mongodbHandler";

export default function Login() {
  const [user, error, status] = useSelector((state) => {
    const user = state.user.user;
    const error = state.user.error;
    const status = state.user.status;
    return [user, error, status];
  });
  const state = useSelector((state) => state);
  const cat = useMemo(() => state.catprofile.data, [state.catprofile.status]);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  function validateEmail(value) {
    let error;

    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }

    return error;
  }

  function validatePassword(value) {
    let error;

    if (!value) {
      error = "Required";
    }
    return error;
  }

  useEffect(() => {
    async function init() {
      if (user !== null) {
        // 
        setClientMongoDB();
        const catProfileRequest = await dispatch(loadCatProfile())
        if (catProfileRequest.payload === null) {
          navigate("/profil");
        } else {
          navigate("/home");
        }
      }
    }

    init();
  }, [user, cat,navigate]);

  useEffect(() => {
    if (error !== null) {
      toast.error("invalid email/password");
    }
  }, [error]);

  const loginAndRedirect = async (values) => {
    if (!user || user.status !== "idle") {
      dispatch(loginUser(values));
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                loginAndRedirect(values);
                actions.setSubmitting(false);
              }, 200);
            }}
          >
            {(props) => (
              <Form>
                <Stack spacing={4}>
                  <Field name="email" validate={validateEmail}>
                    {({ field, form }) => (
                      <FormControl id="email" isInvalid={props.errors.email}>
                        <FormLabel>Email address</FormLabel>
                        <Input {...field} id="email" type="email" />
                        <FormErrorMessage>Email is required.</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="password" validate={validatePassword}>
                    {({ field, form }) => (
                      <FormControl
                        id="password"
                        isInvalid={props.errors.password}
                      >
                        <FormLabel>Password</FormLabel>
                        <Input {...field} id="password" type="password" />
                        <FormErrorMessage>
                          Password is required.
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Stack spacing={10}>
                    <Stack
                      direction={{ base: "column", sm: "row" }}
                      align={"start"}
                      justify={"space-between"}
                    >
                      <Link
                        color={"blue.400"}
                        onClick={() => navigate("/forgot")}
                      >
                        Forgot password?
                      </Link>
                    </Stack>
                    <Button
                      isLoading={props.isSubmitting}
                      type="submit"
                      bg={"tertiary"}
                      color={"black"}
                      _hover={{
                        bg: "primary",
                      }}
                    >
                      Sign in
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
}
