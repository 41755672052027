import { createSlice } from "@reduxjs/toolkit";
import { loadConfigMongoDB, updateConfigMongoDB } from "../api/mongodbHandler";
import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

export const loadConfig = createAsyncThunk("config/load", async () => {
  return loadConfigMongoDB();
});

export const updateConfig = createAsyncThunk(
  "config/update",
  async (payload) => {
    return updateConfigMongoDB(payload);
  }
);

export const configSlice = createSlice({
  name: "config",
  initialState: {
    status: "idle",
    error: null,
    data: null,
  },
  extraReducers: {
    [loadConfig.pending]: (state) => {
      state.data = null;
      state.status = "loading";
      state.error = null;
    },
    [loadConfig.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.status = "fetched";
      state.error = null;
    },
    [loadConfig.rejected]: (state, action) => {
      state.data = null;
      state.status = "failed";
      state.error = action?.error?.message;
    },
    [updateConfig.pending]: (state, action) => {
      if (action.meta.arg.schedules) {
        state.data.schedules = action.meta.arg.schedules;
      }
      if (action.meta.arg.dry_food_refill_timestamp) {
        state.data.dry_food_refill_timestamp =
          action.meta.arg.dry_food_refill_timestamp;
      }
      if (action.meta.arg.mode) {
        state.data.mode = action.meta.arg.mode;
      }
      if (action.meta.arg.free_feeding_mode) {
        state.data.free_feeding_mode = action.meta.arg.free_feeding_mode;
      }
      state.status = "updating";
      state.error = null;
    },
    [updateConfig.fulfilled]: (state, { payload }) => {
      toast.success("L'action a été un succès");
      state.status = "needs_refresh";
      state.error = null;
    },
    [updateConfig.rejected]: (state, action) => {
      state.status = "update_failed";
      state.error = action?.error?.message;
    },
  },
});

export default configSlice.reducer;
