import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
  FormErrorMessage,
} from "@chakra-ui/react";

import { toast } from "react-hot-toast";
import { Formik, Form, Field } from "formik";

import { useState } from "react";
import { app } from "../redux/api/mongodbHandler";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
  const [password, setPassword] = useState(null);
  const navigate = useNavigate();
  function validatePassword(value) {
    let error;
    setPassword(value);
    if (!value) {
      error = "Required";
    }
    return error;
  }

  function validatePasswordRepeat(value) {
    let error;
    if (!value && password) {
      error = "Required";
    }
    if (value !== password) {
      error = "Passwords must be the same";
    }
    return error;
  }

  const resetPassword = async (values) => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const tokenId = params.get("tokenId");
    if (!token || !tokenId) {
      toast.error("You must follow the Link in the confirmation email");
      throw new Error("You must follow the Link in the confirmation email");
    } else {
      try {
        await app.emailPasswordAuth.resetPassword({
          password: values.password,
          token,
          tokenId,
        });
      } catch (errors) {
        toast.error(errors.error);
        throw new Error(errors.error);
      } finally {
        navigate("/login");
      }
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Reset your Password</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Formik
            initialValues={{ password: "", password_repeat: "" }}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                resetPassword(values);
                actions.setSubmitting(false);
              }, 200);
            }}
          >
            {(props) => (
              <Form>
                <Stack spacing={4}>
                  <Field name="password" validate={validatePassword}>
                    {({ field, form }) => (
                      <FormControl
                        id="password"
                        isInvalid={props.errors.password}
                      >
                        <FormLabel>Password</FormLabel>
                        <Input {...field} id="password" type="password" />
                        <FormErrorMessage>
                          Passwords is Required
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field
                    name="password_repeat"
                    validate={validatePasswordRepeat}
                  >
                    {({ field, form }) => (
                      <FormControl
                        id="password_repeat"
                        isInvalid={props.errors.password_repeat}
                      >
                        <FormLabel>Repeat Password</FormLabel>
                        <Input
                          {...field}
                          id="password_repeat"
                          type="password"
                        />
                        <FormErrorMessage>
                          Passwords must match.
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Stack spacing={10}>
                    <Button
                      isLoading={props.isSubmitting}
                      type="submit"
                      bg={"tertiary"}
                      color={"black"}
                      _hover={{
                        bg: "primary",
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
}
