import React, { useEffect } from "react";
import {
  VStack,
  Box,
  Switch,
  FormControl,
  FormLabel,
  Stack,
  Container,
  IconButton,
  Button,
  HStack,
  Text,
} from "@chakra-ui/react";
import CardLayout from "../Layout/CardLayout";
import { Field, Formik, Form } from "formik";
import { ReactComponent as Trash } from "../Icons/trash.svg";
import { useDispatch } from "react-redux";
import ScheduleModal from "../Modals/ScheduleModal";
import { useDisclosure } from "@chakra-ui/react";
import { formattedTime } from "../../pages/Home";
import { useState } from "react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";

//TODO: Alot of Hotfixing for Schema change of Schedule

function ScheduleField({
  item,
  deleteItem,
  onOpen,
  isOpen,
  onClose,
  changeTime,
  changeActive,
  setItem,
  changeDoses,
}) {
  const [active, setActive] = useState(
    item?.todo?.[0] === "wet" ? true : false
  );

  if (!item) return <></>;

  return (
    <Box position={"relative"} key={item.time}>
      <CardLayout
        title={item.time}
        divider={true}
        padding={"0px"}
        width={"100%"}
        titlePostion={"space-between"}
        textPaddingBottom={"0px"}
        titleFontSize={"lg"}
      >
        <HStack style={{ margin: 0 }} justifyContent={"space-between"}>
          <Box style={{ margin: "10px" }} textStyle={"secondary"}>
            <FormControl display="flex" alignItems="center">
              <FormLabel
                htmlFor="email-alerts"
                mb="0"
                fontSize={"xs"}
                fontWeight={active ? "normal" : "bold"}
                marginInlineEnd={"5px"}
              >
                Croqu.
              </FormLabel>
              <Switch
                size="sm"
                onChange={(e) => {
                  changeActive(item, e.target.checked);
                  setActive(!active);
                }}
                defaultChecked={active}
              />
              <FormLabel
                htmlFor="email-alerts"
                mb="0"
                marginInlineStart={"5px"}
                fontSize={"xs"}
                fontWeight={active ? "bold" : "normal"}
              >
                Pâtée
              </FormLabel>
            </FormControl>
          </Box>
          <Box>
            <HStack marginEnd={"10px"}>
              <IconButton
                isRound
                variant="outline"
                // size={"xs"}
                height={"15px"}
                minWidth={"15px"}
                borderColor={"black"}
                icon={<MinusIcon height={"7px"} width={"7px"} />}
                onClick={() => {
                  if (item.todo.length > 1) {
                    changeDoses(item, item.todo.length - 1);
                  }
                }}
              />
              <VStack>
                <Text
                  fontSize={"xs"}
                  textStyle={"secondary"}
                  lineHeight={"initial"}
                >
                  {item.todo.length}{" "}
                  {item.todo.length > 1 || item.todo.length < -1
                    ? "doses"
                    : "dose"}
                </Text>
              </VStack>
              <IconButton
                isRound
                height={"15px"}
                minWidth={"15px"}
                variant="outline"
                borderColor={"black"}
                icon={<AddIcon height={"7px"} width={"7px"} />}
                onClick={() => {
                  changeDoses(item, item.todo.length + 1);
                }}
              />
            </HStack>
          </Box>
        </HStack>
      </CardLayout>
      <Box position={"absolute"} top={"0px"} right={"10px"}>
        <IconButton
          icon={<Trash />}
          bg={"transparent"}
          _hover={{ bg: "transparent" }}
          onClick={() => deleteItem(item)}
        />
      </Box>
      <Box position={"absolute"} top={"0px"} left={"10px"}>
        <Button
          width={"250px"}
          bg={"transparent"}
          _hover={{ bg: "transparent" }}
          _focus={{ boxShadow: "none", bg: "transparent" }}
          _active={{ boxShadow: "none", bg: "transparent" }}
          onClick={() => {
            onOpen();
            setItem(item);
          }}
        />
      </Box>
    </Box>
  );
}

function Schedule({ gap, schedule, updateConfig }) {
  const dispatch = useDispatch();

  const deleteItem = (item) => {
    dispatch(
      updateConfig({
        schedules: schedule.filter((i) => i !== item),
      })
    );
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const changeTime = (item, time) => {
    dispatch(
      updateConfig({
        schedules: [
          ...schedule.filter((i) => i !== item),
          { time: formattedTime(time), todo: item.todo },
        ],
      })
    );
  };

  const changeActive = (item, active) => {
    dispatch(
      updateConfig({
        schedules: [
          ...schedule.filter((i) => i !== item),
          {
            time: item.time,
            todo: active
              ? Array.from({ length: item.todo.length }, (_, i) => "wet")
              : Array.from({ length: item.todo.length }, (_, i) => "dry"),
          },
        ],
      })
    );
  };

  const changeDoses = (item, doses) => {
    if (item.todo.length !== doses) {
      dispatch(
        updateConfig({
          schedules: [
            ...schedule.filter((i) => i !== item),
            {
              time: item.time,
              todo: Array.from({ length: doses }, (_, i) => item.todo[0]),
            },
          ],
        })
      );
    }
  };

  const [item, setItem] = useState();

  return (
    <VStack paddingTop={5}>
      <Box width={"95%"}>
        <Stack>
          {schedule.map((item, index) => (
            <ScheduleField
              key={index}
              item={item}
              deleteItem={deleteItem}
              onOpen={onOpen}
              isOpen={isOpen}
              onClose={onClose}
              changeTime={changeTime}
              changeActive={changeActive}
              setItem={setItem}
              changeDoses={changeDoses}
            />
          ))}
        </Stack>
      </Box>
      <ScheduleModal
        isOpen={isOpen}
        onClose={onClose}
        item={item}
        changeTime={changeTime}
      />
    </VStack>
  );
}

export default Schedule;
