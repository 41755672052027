import React from "react";

export const races = [
  "Croisé(e)  ou Autre Race",
  "Chat type Européen poil court",
  "Chat type Européen poil mi-long",
  "Chat type Européen poil long",
  "Abyssin",
  "Américain Bobtail",
  "Américain Curl",
  "Américain Shorthair",
  "Angora Turc",
  "Balinais",
  "Bengal",
  "Birman",
  "Bleu Russe",
  "Bobtail Japonais",
  "Bombay",
  "British Longhair",
  "British Shorthair",
  "Burmese",
  "Chartreux",
  "Cornish Rex",
  "Devon Rex",
  "Exotic Shorthair",
  "Havana Brown",
  "Highland Fold",
  "Javanais",
  "Korat",
  "Maine Coon",
  "Mandarin",
  "Manx",
  "Mau Egyptien",
  "Munchkin",
  "Norvégien",
  "Oriental Shorthair",
  "Persan",
  "Pixie Bob",
  "Ragdoll",
  "Sacré de Birmanie",
  "Savannah",
  "Scottish Fold",
  "Siamois",
  "Sibérien",
  "Snow shoe",
  "Somali",
  "Sphynx",
  "Thaï",
  "Tonkinois",
  "Turc de Van",
];

export const states = [
  "En surpoids",
  "Un poil enrobé",
  "Parfait",
  "Un poil maigre",
  "Maigre",
];
export const activities = [
  "Sans accès extérieur",
  "Balcon",
  "Accès occasionel à l'extérieur",
  "Accès à l'extérieur",
];
export const temperaments = ["Très calme", "Calme", "Actif", "Très actif"];

export function getMonthDifference(startDate, endDate) {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
}

export function algorithm(cat) {
  let K = 1;

  //race
  switch (cat.cat_race) {
    case "Bengal":
      K = K * 1.1;
      break;
    case "Abyssin":
      K = K * 1.2;
      break;
    case "Oriental Shorthair":
      K = K * 1.1;
      break;
    case "Savannah":
      K = K * 1.1;
      break;
    case "Sphynx":
      K = K * 1.2;
      break;
  }

  //age
  const age = getMonthDifference(cat.cat_age, new Date());
  switch (true) {
    case age < 4:
      K = K * 2;
      break;
    case age < 6:
      K = K * 1.6;
      break;
    case age < 8:
      K = K * 1.3;
      break;
    case age < 12:
      K = K * 1.1;
      break;
  }

  //sterilisation
  if (cat.cat_sterilized === "false") {
    K = K * 0.8;
  }

  // temperament
  switch (cat.cat_temperament) {
    case "Calme":
      K = K * 0.9;
      break;
    case "Très calme":
      K = K * 0.8;
      break;
    case "Très actif":
      K = K * 1.05;
      break;
  }

  //state
  switch (cat.cat_state) {
    case "En surpoids":
      K = K * 0.8;
      break;
    case "Un poil enrobé":
      K = K * 0.9;
      break;
    case "Parfait":
      K = K * 1;
      break;
    case "Un poil maigre":
      K = K * 1.1;
      break;
    case "Maigre":
      K = K * 1.2;
      break;
  }

  //activity
  switch (cat.cat_activity) {
    case "Sans accès extérieur":
      K = K * 0.85;
      break;
    case "Balcon":
      K = K * 0.87;
      break;
    case "Accès occasionel à l'extérieur":
      K = K * 0.9;
      break;
    case "Accès à l'extérieur":
      K = K * 1;
      break;
  }

  const kcal_per_kg = 70;

  const result = Math.round(cat.cat_ideal * K * kcal_per_kg);

  //TODO: Temporary fix in order to have the clean numbers, the daily need gets rounded to the next number dividable by 20, because the machine can only give 20kcal at once

  if (result % 20 < 10) {
    return result - (result % 20);
  } else {
    return result - (result % 20) + 20;
  }

  return Math.round(cat.cat_ideal * K * kcal_per_kg);
}
