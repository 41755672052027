import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Select,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../redux/slices/configSlice";
import CardLayout from "../Layout/CardLayout";

function SwitchMode({ cat, changeActiveSwitch, active }) {
  return (
    <CardLayout title={"Horaires de distribution"} width={"100%"}>
      <VStack position={"relative"} spacing={5}>
        <HStack textStyle={"secondary"} width={"100%"} paddingBottom={4}>
          <FormControl
            display="flex"
            alignItems="center"
            justifyContent={"center"}
          >
            <Grid templateColumns={"42% 16% 42%"} width={"100%"} px={2}>
              <GridItem>
                <FormLabel
                  htmlFor="email-alerts"
                  mb="0"
                  fontSize={"xs"}
                  fontWeight={active ? "normal" : "bold"}
                  marginInlineEnd={"2px"}
                  textAlign={"end"}
                  display={"flex"}
                  alignItems={"end"}
                  flexDirection={"column"}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"inherit"}
                  >
                    <Text
                      textAlign={"end"}
                      width={"100%"}
                      textStyle={"tertiary"}
                      fontSize={"xs"}
                      color="text" //primary.500 Wet
                    >
                      {cat?.cat_name.toUpperCase()} CHOISIT SES HORAIRES
                    </Text>
                    {/* <Text textAlign={"end"} width={"max-content"}>
                      
                    </Text> */}
                  </Box>
                </FormLabel>
              </GridItem>
              <GridItem>
                <Switch
                  size="sm"
                  onChange={(e) => {
                    changeActiveSwitch(!active);
                    // dispatch(
                    //   updateConfig({
                    //     mode: active ? "free_feeding" : "schedule",
                    //   })
                    // );
                  }}
                  defaultChecked={active}
                />
              </GridItem>
              <GridItem>
                <FormLabel
                  htmlFor="email-alerts"
                  mb="0"
                  marginInlineStart={"2px"}
                  marginInlineEnd={0}
                  fontSize={"xs"}
                  fontWeight={active ? "bold" : "normal"}
                >
                  <Box>
                    <Text
                      width={"max-content"}
                      textStyle={"tertiary"}
                      fontSize={"xs"}
                      color="text" //primary.500 Wet
                    >
                      HORAIRES <br />
                      FIXES{" "}
                    </Text>
                  </Box>
                </FormLabel>
              </GridItem>
            </Grid>
          </FormControl>
        </HStack>
      </VStack>
    </CardLayout>
  );
}

export default SwitchMode;
