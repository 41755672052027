import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import configReducer from "./slices/configSlice";
import telemetryReducer from "./slices/telemetrySlice";
import aggregatedDataReducer from "./slices/aggregatedDataSlice";
import userReducer from "./slices/userSlice";
import clientReducer from "./slices/clientSlice";
import catprofileReducer from "./slices/catProfileSlice";
import imagesReducer from "./slices/imagesSlice";

export const store = configureStore(
  {
    reducer: {
      config: configReducer,
      telemetry: telemetryReducer,
      aggregatedData: aggregatedDataReducer,
      user: userReducer,
      client: clientReducer,
      catprofile: catprofileReducer,
      images: imagesReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            "user/loginUser/fulfilled",
            "config/load/fulfilled",
            "telemetry/load/fulfilled",
            "aggreagtedData/load/fulfilled",
            "catprofile/load/fulfilled",
            "telemetry/loadWeek/fulfilled",
          ],
          ignoreState: true,
        },
      }),
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
