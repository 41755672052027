import { VStack, Box, useDisclosure, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CatProfil from "../components/Cards/CatProfil";
import FreeFeedingMode from "../components/Cards/FreeFeedingMode";
import SwitchMode from "../components/Cards/SwitchMode";
import Layout from "../components/Layout/Layout";
import CatModal from "../components/Modals/CatModal";
import ApplyButtonSchedule from "../components/Other/ApplyButtonSchedule";
import { updateCatProfile } from "../redux/slices/catProfileSlice";
import Loading from "./Loading";

function Profil() {
  const state = useSelector((state) => state);
  const cat = state.catprofile;
  const cat_data = cat.data;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [calcedScheduleGlobal, setCalcedScheduleGlobal] = useState({});
  const [freeFeedingModeGlobal, setFreeFeedingModeGlobal] = useState({});

  const [active, setActive] = React.useState(
    state.config.data?.mode === "free_feeding" ? false : true
  );

  const changeCalcedScheduleGlobal = (calcedScheduleGlobal) => {
    setCalcedScheduleGlobal(calcedScheduleGlobal);
  };

  const changeFreeFeedingModeGlobal = (freeFeedingModeGlobal) => {
    setFreeFeedingModeGlobal(freeFeedingModeGlobal);
  };

  const changeActiveSwitch = (active) => {
    setActive(active);
  };

  if (cat.status === "loading") console.log("loading..");

  return (
    <Layout>
      <VStack>
        <Box maxW={"400px"} width={"95%"}>
          <CatProfil cat={cat_data} onOpen={onOpen} />
          <CatModal
            isOpen={isOpen}
            onClose={onClose}
            updateCatProfile={updateCatProfile}
            cat={cat_data}
          />
          {cat_data && state.config.data ? (
            <>
              <FreeFeedingMode
                cat={cat_data}
                config={state.config.data}
                setCalcedScheduleGlobal={changeCalcedScheduleGlobal}
                setFreeFeedingModeGlobal={changeFreeFeedingModeGlobal}
              />
              <SwitchMode
                cat={cat_data}
                active={active}
                changeActiveSwitch={changeActiveSwitch}
              />
              <ApplyButtonSchedule
                calcedSchedule={calcedScheduleGlobal}
                freeFeedingMode={freeFeedingModeGlobal}
                active={active}
              />
              <Box height={"3rem"}></Box>
            </>
          ) : (
            <Text margin={5}>
              {" "}
              Pour obtenir un plan alimentaire, vous devez créer un profil pour
              votre chat et être connecté à une machine.
            </Text>
          )}
        </Box>
      </VStack>
    </Layout>
  );
}

export default Profil;
