import { Box, GridItem, HStack, Text, VStack, Grid } from "@chakra-ui/react";
import React, { useMemo } from "react";
import Needed from "../Graphs/Needed";
import CardLayout from "../Layout/CardLayout";
import { toCalories } from "../Algorithms/CalcSchedule";
import { ReactComponent as WetFood } from "../Icons/wet_food.svg";
import { ReactComponent as DryFood } from "../Icons/dry_food.svg";

function CurrentSchedule({ schedule, config, cat }) {
  const totalWet = useMemo(() => {
    let tmp = { weight: 0, doses: 0 };
    //TODO: Hotfix for Dataschema Schedule Rework
    schedule.forEach((s) => {
      if (s.todo === undefined) {
        if (s.active) {
          tmp.weight += config.wet_weight_by_dose;
          tmp.doses += 1;
        }
      } else {
        s.todo.map((todo) => {
          if (todo === "wet") {
            tmp.weight += config.wet_weight_by_dose;
            tmp.doses += 1;
          }
        });
      }
    });
    return tmp;
  }, [schedule]);
  const totalDry = useMemo(() => {
    let tmp = { weight: 0, doses: 0 };
    //TODO: Hotfix for Dataschema Schedule Rework
    schedule.forEach((s) => {
      if (s.todo === undefined) {
        if (!s.active) {
          tmp.weight += config.dry_weight_by_dose;
        }
      } else {
        s.todo.map((todo) => {
          if (todo === "dry") {
            tmp.weight += config.dry_weight_by_dose;
            tmp.doses += 1;
          }
        });
      }
    });
    return tmp;
  }, [schedule]);

  return (
    <Box
      position={"fixed"}
      left={"50%"}
      width={"95%"}
      transform={"translateX(-50%)"}
      zIndex={1}
      top={"38px"}
      backgroundColor={"primary.main"}
    >
      <CardLayout
        title={"Quantités journalières"}
        divider={true}
        width={"100%"}
      >
        <Box>
          <HStack
            // justify={"space-around"}
            justify={"center"}
            align={"center"}
          >
            <Box>
              <Needed schedule={schedule} config={config} cat={cat} />
            </Box>
            <Box>
              <Grid templateColumns={"1fr 1fr"} rowGap={"5px"}>
                <GridItem
                  display={"grid"}
                  alignContent={"center"}
                  justifyContent={"center"}
                >
                  <WetFood height={"60px"} fill="white" stroke="#C6E1FF" />
                </GridItem>
                <GridItem
                  display={"grid"}
                  alignContent={"center"}
                  justifyContent={"start"}
                >
                  <VStack
                    align={"baseline"}
                    spacing={0}
                    lineHeight={1.5}
                    alignItems={"flex-start"}
                  >
                    <Text
                      textStyle={"tertiary"}
                      fontSize={"xs"}
                      color="text" //primary.500 Wet
                    >
                      PÂTÉE
                    </Text>
                    <Text fontWeight={"bold"} fontSize={"xs"}>
                      {totalWet.weight}g
                    </Text>
                    <Text fontSize={"xs"}>
                      {toCalories("wet", totalWet.weight)} Kcal -{" "}
                      {totalWet.weight === 0
                        ? 0
                        : Math.round(
                            (toCalories("wet", totalWet.weight) /
                              (toCalories("wet", totalWet.weight) +
                                toCalories("dry", totalDry.weight))) *
                              100
                          )}
                      %
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem
                  display={"grid"}
                  alignContent={"center"}
                  justifyContent={"center"}
                >
                  <DryFood height={"25px"} fill="#FFDED3" stroke="#FFDED3" />
                </GridItem>
                <GridItem
                  display={"grid"}
                  alignContent={"center"}
                  justifyContent={"start"}
                >
                  <VStack
                    fontSize={"xs"}
                    align={"baseline"}
                    alignItems={"flex-start"}
                    spacing={0}
                    lineHeight={1.5}
                  >
                    <Text color="text" textStyle={"tertiary"} fontSize={"xs"}>
                      CROQUETTES
                    </Text>
                    <Text fontWeight={"bold"}>{totalDry.weight}g</Text>
                    <Text fontSize={"xs"}>
                      {toCalories("dry", totalDry.weight)} Kcal -{" "}
                      {totalDry.weight === 0
                        ? 0
                        : Math.round(
                            (toCalories("dry", totalDry.weight) /
                              (toCalories("wet", totalWet.weight) +
                                toCalories("dry", totalDry.weight))) *
                              100
                          )}
                      %
                    </Text>
                  </VStack>
                </GridItem>
              </Grid>
            </Box>
          </HStack>
          <Box padding={"10px"} justifyContent={"flex-end"} display={"flex"}>
            <Text color="text" fontSize={"xs"} textTransform={"uppercase"}>
              Nombre de repas : {schedule.length}
            </Text>
          </Box>
        </Box>
      </CardLayout>
    </Box>
  );
}

export default CurrentSchedule;
