import React from "react";
import {
  GridItem,
  HStack,
  Grid,
  Box,
  Text,
  VStack,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { getMonthDifference } from "../Algorithms/Algorithm";
import ChooseCatModal from "../Modals/ChooseCatModal";

function Value({ title, value }) {
  const displayTitle = (title) => {
    switch (title) {
      case "cat_race":
        return "RACE";
      case "cat_age":
        return "AGE";
      case "cat_weight":
        return "POIDS";
      case "cat_sex":
        return "SEXE";
      case "cat_state":
        return "ÉTAT";
      case "cat_sterilised":
        return "STÉRILISÉ";
      case "cat_activity":
        return "ACTIVITÉ";
      case "cat_ideal":
        return "IDÉAL";
      default:
        return "";
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"baseline"}
      key={title}
    >
      <Text textStyle={"tertiary"} fontSize={"10px"}>
        {displayTitle(title)}
      </Text>
      <Text fontWeight={"bold"} fontSize={"12px"} textAlign={"start"}>
        {title === "cat_sterilised"
          ? value === "true"
            ? "Oui"
            : "Non"
          : title === "cat_weight"
          ? value + " kg"
          : title === "cat_age"
          ? getMonthDifference(value, new Date()) > 23
            ? new Date().getFullYear() - value.getFullYear() + " ans"
            : getMonthDifference(value, new Date()) + " mois"
          : title === "cat_ideal"
          ? value + " kg"
          : title === "cat_sex"
          ? value === "mâle"
            ? "Mâle"
            : "Femelle"
          : value}
      </Text>
    </Box>
  );
}

function CatProfil({ cat, onOpen }) {
  return (
    <Box
      height={"auto"}
      bg={useColorModeValue("white", "gray.900")}
      // boxShadow={"xl"}
      // borderLeft={"1px solid"}
      // borderBottom={"3px solid"}
      // borderRight={"1px solid"}
      // borderColor={"primary.50"}
      rounded={"xl"}
      margin={"10px"}
      textAlign={"center"}
      boxShadow={"rgba(0, 0, 0, 0.1) 0px 4px 12px"}
    >
      <Box position={"relative"}>
        <Grid templateColumns={"60% 40%"}>
          <GridItem>
            <Text
              lineHeight={"normal"}
              padding={"10px"}
              paddingBottom={"10px"}
              textAlign={"left"}
              fontWeight={"bold"}
              fontSize={"md"}
              marginLeft="10px"
            >
              {cat ? cat.cat_name : "On ne connaît pas encore votre chat..."}
            </Text>
          </GridItem>
          <GridItem padding={"10px"} alignItems={"flex-end"}>
            <Link
              onClick={onOpen}
              borderColor={"black"}
              borderRadius={"xl"}
              display="inline-block"
              borderWidth={"1px"}
              paddingLeft={"1rem"}
              paddingRight={"0.8rem"}
              paddingTop={"1px"}
            >
              <Text fontSize={"xs"} textStyle={"secondary"} float="left">
                {cat ? "Modifier" : "Créer"}
              </Text>
              <TriangleDownIcon
                fontSize={12}
                marginLeft={"10px"}
                float="right"
                marginTop="2px"
              />
            </Link>
          </GridItem>
        </Grid>
        <Grid templateColumns={"30% 70%"}>
          <GridItem
            justifyContent={"center"}
            display={"flex"}
            alignContent={"flex-start"}
            alignItems={"center"}
          >
            <ChooseCatModal cat={cat} />
          </GridItem>
          <GridItem alignSelf={"center"}>
            {cat ? (
              <Box>
                <Grid
                  templateColumns={"33% 33% 33%"}
                  rowGap={2}
                  marginBottom={5}
                >
                  {Object.entries(cat)
                    .sort((a, b) => {
                      if (a[0] === "cat_race") {
                        return 1;
                      } else {
                        return -1;
                      }
                    })
                    .map((key) => {
                      if (
                        key[0] === "_id" ||
                        key[0] === "user_id" ||
                        key[0] === "cat_name" ||
                        key[0] === "cat_need" ||
                        key[0] === "cat_temperament" ||
                        key[0] === "cat_ideal" ||
                        key[0] === "icon"
                      ) {
                        return null;
                      }
                      return (
                        <GridItem key={key[0]} pr={2}>
                          <Value title={key[0]} value={key[1]} />
                        </GridItem>
                      );
                    })}
                  <GridItem
                    colSpan={2}
                    display="flex"
                    justifyContent={"flex-start"}
                  >
                    <Box
                      bg={"primary.50"}
                      height={"100%"}
                      width={"90%"}
                      borderRadius={"2xl"}
                      // dropShadow={true}
                      paddingTop={"8px"}
                      // borderBottom={"2px solid"}
                      // borderColor={"primary.100"}
                    >
                      <VStack>
                        <Text
                          fontSize={"xs"}
                          color={"primary.700"}
                          textStyle={"tertiary"}
                        >
                          BESOIN/JOUR
                        </Text>
                        <HStack
                          alignItems={"baseline"}
                          margin={"0px !important"}
                        >
                          <Text fontWeight={"bold"} fontSize={"3xl"}>
                            {cat.cat_need}
                          </Text>
                          <Text fontSize={"xs"} textStyle={"tertiary"}>
                            KCAL
                          </Text>
                        </HStack>
                      </VStack>
                    </Box>
                  </GridItem>
                </Grid>
              </Box>
            ) : (
              <></>
            )}
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
}
export default CatProfil;
