import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  VStack,
  HStack,
  Box,
  Text,
  SimpleGrid,
  Grid,
  Button,
  GridItem,
  background,
} from "@chakra-ui/react";
import { ReactComponent as Trash } from "../Icons/trash.svg";
import { Divider } from "@chakra-ui/react";
import { ReactComponent as DryFood } from "../Icons/dry_food.svg";
import { ReactComponent as WetFood } from "../Icons/wet_food.svg";
import { ReactComponent as Pot } from "../Icons/pot.svg";
import { ReactComponent as Camera } from "../Icons/camera.svg";

import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../redux/slices/configSlice";
import { formattedTime } from "../../pages/Home";
import { useMemo } from "react";
import { loadTelemetry } from "../../redux/slices/telemetrySlice";
import { monitorListingsUsingEventEmitter } from "../../redux/api/mongodbHandler";
import { loadImages } from "../../redux/slices/imagesSlice";

function Layout({ icon, text, action }) {
  return (
    <HStack>
      <Button
        width={"100%"}
        justifyContent="start"
        bg={"primary"}
        _focus={{ boxShadow: "none", bg: "primary.main" }}
        onClick={() => {
          setTimeout(function () {
            action();
          }, 1000);
        }}
      >
        <HStack spacing={2}>
          <Box>{icon}</Box>
          <Box>
            <Text textStyle={"primary"} fontWeight={"light"}>
              {text}
            </Text>
          </Box>
        </HStack>
      </Button>
    </HStack>
  );
}

function ActionButtonModal({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const schedules = useMemo(() => {
    return state.config.data?.schedules;
  }, [state]);

  const schedule_sorted = useMemo(() => {
    if (schedules) {
      let tmp = [...schedules];
      tmp.sort((a, b) => {
        if (a.time < b.time) {
          return -1;
        } else {
          return 1;
        }
      });
      return tmp;
    } else {
      return [];
    }
  }, [schedules]);

  const pushDryFood = () => {
    dispatch(updateConfig({ push_dry_food: true, timestamp: new Date() }));
    onClose();
  };
  const openDrawer = () => {
    dispatch(updateConfig({ toggle_drawer: true, timestamp: new Date() }));
    onClose();
  };
  const pushWetFood = () => {
    dispatch(updateConfig({ push_wet_food: true, timestamp: new Date() }));
    onClose();
  };
  const takePicture = async () => {
    dispatch(updateConfig({ take_picture: true, timestamp: new Date() }));
    onClose();
    const updated = await monitorListingsUsingEventEmitter();
    if (updated) {
      dispatch(loadTelemetry());
      dispatch(loadImages());
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        roundedTopLeft={5}
        roundedTopRight={5}
        position="fixed"
        bottom={-3}
        mb={0}
        bg="white"
        paddingBottom={10}
      >
        <ModalCloseButton />
        <ModalBody marginTop={3}>
          <Box marginBottom={"15px"}>
            <VStack></VStack>
            <Text textStyle={"tertiary"} fontSize={"xs"} color={"grey"}>
              ACTIONS
            </Text>
            <Stack marginTop={"10px"} textStyle={"secondary"} spacing={"20px"}>
              <Layout
                text={"Prendre une photo"}
                icon={<Camera height={"25px"} width={"25px"} />}
                action={takePicture}
              />
              <Layout
                text={"Distribuer une portion de pâtée"}
                icon={
                  <WetFood height={"25px"} width={"25px"} stroke={"black"} />
                }
                action={pushWetFood}
              />
              <Layout
                text={"Distribuer une portion de croquettes"}
                icon={
                  <DryFood height={"25px"} width={"25px"} stroke={"black"} />
                }
                action={pushDryFood}
              />
              <Layout
                text={"Ouvrir / Fermer la gamelle"} //open the drawer
                icon={<Pot height={"25px"} width={"25px"} />}
                action={openDrawer}
              />
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ActionButtonModal;
