import { DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CardLayout from "../Layout/CardLayout";
import Placeholder from "./Placeholder";
import FsLightbox from "fslightbox-react";

export function ImageCard({ image, height, open, top = false }) {
  if (top) {
    return (
      <Box height={"80px"} width={"90%"} position={"relative"}>
        <Image
          src={image.url}
          height={"100%"}
          fit={"cover"}
          width={"100%"}
          borderRadius="5px"
          onClick={() => open()}
        />
        <Text
          fontSize={"8px"}
          fontWeight={"bold"}
          color={"secondary"}
          position={"absolute"}
          top={"2px"}
          left={"5px"}
        >
          {image.title.slice(0, 5)}
        </Text>
      </Box>
    );
  }
  return (
    <Box height={"100%"} width={"100%"} position={"relative"}>
      <Image
        src={image.url}
        fit={"cover"}
        height={height}
        borderRadius="5px"
        onClick={() => open()}
        style={{ aspectRatio: 1 }}
        width={"auto"}
      />
      <Text
        fontSize={"8px"}
        fontWeight={"bold"}
        color={"secondary"}
        position={"absolute"}
        top={"2px"}
        left={"5px"}
      >
        {image.title}
      </Text>
    </Box>
  );
}

const prepareImages = (todaysData) => {
  let images = [];
  todaysData?.images?.forEach((image) => {
    images.push(
      <Box po>
        <Box
          position={"relative"}
          justifyContent={"center"}
          display={"flex"}
          alignContent={"center"}
          alignItems={"center"}
          width={"80vw"}
          minHeight={400}
          mx={"auto"}
        >
          {/* //{" "} */}
          <Image
            src={image.url}
            height={"100%"}
            fit={"contain"}
            width={"100%"}
          />
          <Text
            fontSize={"20px"}
            fontWeight={"bold"}
            color={"secondary"}
            position={"absolute"}
            top={"2px"}
            left={"5px"}
          >
            {image.title.slice(0, 5)}
          </Text>
        </Box>
        <Container
          width={"fit-content"}
          height={"fit-content"}
          position={"absolute"}
          right={0}
          top={0}
          bg={"rgba(0, 0, 0, 0.6)"}
          padding={0}
          rounded={"5px"}
        >
          <IconButton
            // position={"absolute"}
            // right={0}
            // bottom={0}
            bg={"transparent"}
            _hover={{ bg: "primary" }}
            _focus={{ bg: "primary" }}
            // onClick={() => downloadImage(image)}
            icon={
              <a href={image.url} download>
                <DownloadIcon color={"white"} opacity={"1 !important"} />
              </a>
            }
          />
        </Container>
      </Box>
    );
  });
  return images;
};

function Pictures({ todaysData }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <CardLayout width="50%" title={"Photos"} height="100%">
      <Box marginBottom={"20px"}>
        {todaysData && todaysData.images?.length > 0 ? (
          <VStack>
            <ImageCard
              image={todaysData.images[0]}
              height={"50px"}
              open={() => {
                setIsOpen(!isOpen);
              }}
              top={true}
              key={todaysData.images[0].url}
            />
            <HStack width={"90%"}>
              {todaysData.images.map((image, index) => {
                if (index > 0 && index < 4) {
                  return (
                    <ImageCard
                      image={image}
                      height={"30px"}
                      open={() => setIsOpen(!isOpen)}
                      key={index}
                    />
                  );
                }
              })}
            </HStack>
          </VStack>
        ) : (
          <Text style={{ padding: 20 }}>Aucune photo aujourd'hui</Text>
          // <Placeholder />
        )}
      </Box>
      <FsLightbox
        toggler={isOpen}
        sources={prepareImages(todaysData)}
        captions={todaysData?.images?.flatMap((item) => item.title)}
        slide={1}
      />
    </CardLayout>
  );
}

export default Pictures;
