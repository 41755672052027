import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  fonts: {
    heading: "DriveMono",
    body: `"BrooklynSamuelsFive-Medium", "ChaletBook"`,
  },
  textStyles: {
    primary: {
      fontFamily: "BrooklynSamuelsFive-Medium",
    },
    secondary: {
      fontFamily: "ChaletBook",
    },
    tertiary: {
      fontFamily: "DriveMono",
    },
  },
  components: {
    Button: {
      baseStyle: {
        textStyle: "tertiary",
      },
    },
    Switch: {
      variants: {
        noFocus: (props) => ({
          track: {
            bg: "primary.100",
            _focus: {
              boxShadow: "none",
            },
            _checked: {
              bg: "primary.100",
            },
          },
          thumb: {
            bg: "tertiary",
            _checked: {
              bg: "tertiary",
            },
          },
          defaultProps: {
            colorScheme: "primary",
          },
        }),
      },
      defaultProps: {
        variant: "noFocus",
      },
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: "primary.main",
      },
    }),
  },
  colors: {
    primary: {
      main: "#F9F8F6",
      50: "#f5f3f0",
      100: "#dedbd6",
      200: "#c8c3bb",
      300: "#b4ac9d",
      400: "#9f947f",
      500: "#867b66",
      600: "#685f4f",
      700: "#4a443a",
      800: "#2c2923",
      900: "#0f0e0a",
    },
    secondary: "#FFFFFF",
    tertiary: "#FCFF79",
    text: "#6D6D6D",
    blue: "#B8E1FF",
    pink: "#F1D5D3",
    progress: {
      main: "#C6E1FF",
      50: "#C6E1FF",
      100: "#C6E1FF",
      200: "#C6E1FF",
      300: "#C6E1FF",
      400: "#C6E1FF",
      500: "#C6E1FF",
      600: "#C6E1FF",
      700: "#C6E1FF",
      800: "#C6E1FF",
      900: "#C6E1FF",
    },
    progress_second: {
      main: "#FFDED3",
      50: "#FFDED3",
      100: "#FFDED3",
      200: "#FFDED3",
      300: "#FFDED3",
      400: "#FFDED3",
      500: "#FFDED3",
      600: "#FFDED3",
      700: "#FFDED3",
      800: "#FFDED3",
      900: "#FFDED3",
    },
    primary300: "#b4ac9d",
    dry_food: {
      main: "#FFDED3",
      50: "#FFF5F2",
    },
    wet_food: {
      main: "#C6E1FF",
      50: "#EEF6FF",
      100: "#FFDED3",
      200: "#FFDED3",
      300: "#FFDED3",
      400: "#FFDED3",
      500: "#FFDED3",
      600: "#FFDED3",
      700: "#FFDED3",
      800: "#FFDED3",
      900: "#FFDED3",
    },
  },
});

export default theme;
