import React from "react";
import { Box, Progress, VStack, Text, Grid, GridItem } from "@chakra-ui/react";
import { ReactComponent as WetFood } from "../Icons/wet_food.svg";
import { ReactComponent as DryFood } from "../Icons/dry_food.svg";

function DayProgress({ todaysData, toCalories}) {
  const size = "80px";
  return (
    <Box>
      <Grid templateColumns={"1fr 1fr"} rowGap={"5px"}>
        <GridItem
          display={"grid"}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <WetFood height={"60px"} fill="white" stroke="#C6E1FF" />
        </GridItem>
        <GridItem
          display={"grid"}
          alignContent={"center"}
          justifyContent={"start"}
        >
          <VStack align={"baseline"} spacing={0} lineHeight={1.5}>
            <Text
              textStyle={"tertiary"}
              fontSize={"xs"}
              color="text" //primary.500 Wet
            >
              PÂTÉE
            </Text>
            <Text fontWeight={"bold"} fontSize={"xs"}>
              {todaysData.wet_food_eaten}/{todaysData.wet_food_planned}g
            </Text>
            <Text fontSize={"xs"}>
            {toCalories("wet", todaysData.wet_food_eaten)} Kcal {todaysData.wet_food_planned !== 0 ? <>
              - {todaysData.wet_food_eaten === 0
                ? 0
                : Math.ceil(Math.round(
                  (toCalories("wet", todaysData.wet_food_eaten) / (toCalories("wet", todaysData.wet_food_planned) + toCalories("dry", todaysData.dry_food_planned))) *
                    100
                ) / 5) * 5} % 
            </> : null}
            </Text>
          </VStack>
        </GridItem>
        <GridItem
          display={"grid"}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <DryFood height={"25px"} fill="#FFDED3" stroke="#FFDED3" />
        </GridItem> 
        <GridItem
          display={"grid"}
          alignContent={"center"}
          justifyContent={"start"}
        >
          <VStack
            fontSize={"xs"}
            align={"baseline"}
            spacing={0}
            lineHeight={1.5}
          >
            <Text color="text" textStyle={"tertiary"} fontSize={"xs"}>
              CROQUETTES
            </Text>
            <Text fontWeight={"bold"}>
              {todaysData.dry_food_eaten}/{todaysData.dry_food_planned}g
            </Text>
             <Text fontSize={"xs"}>
            {toCalories("dry", todaysData.dry_food_eaten)} Kcal {todaysData.dry_food_planned !== 0 ? <>
              - {todaysData.dry_food_eaten === 0
                ? 0
                : Math.ceil(Math.round(
                  (toCalories("dry", todaysData.dry_food_eaten) / (toCalories("wet", todaysData.wet_food_planned) + toCalories("dry", todaysData.dry_food_planned))) *
                    100
                ) / 5) * 5}   %
            </> : null }
            
            </Text> 
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default DayProgress;
