import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button,
  Box,
  VStack,
} from "@chakra-ui/react";
import { Formik, Form, useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ScheduleModal({ isOpen, onClose, item, changeTime }) {
  const DatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
      <Box
        border={"1px solid "}
        borderRadius={"5px"}
        width={"fit-content"}
        borderColor={"primary.100"}
      >
        <DatePicker
          {...field}
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="HH:mm aa"
          timeFormat="HH:mm"
        />
      </Box>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Choisissez une heure</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              time: new Date().setHours(
                item?.time.slice(0, 2),
                item?.time.slice(3, 5),
                "00"
              ),
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                changeTime(item, values.time);
                setSubmitting(false);
              }, 500);
            }}
          >
            {(props) => (
              <Form>
                <VStack marginBottom={"20px"}>
                  <DatePickerField name="time" />
                  <Button
                    bg={"primary.100"}
                    _focus={{ border: "none" }}
                    type="submit"
                    onClick={onClose}
                  >
                    MODIFIER
                  </Button>
                </VStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ScheduleModal;
