import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginRealm, setClientMongoDB } from "../api/mongodbHandler";
import { app } from "../api/mongodbHandler";
import Cookies from "universal-cookie";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (credentials) => {
    return loginRealm(credentials);
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: app.currentUser || null,
    status: app.currentUser ? "loggedin" : "idle",
    error: null,
  },
  reducers: {
    logoutUser: (state, action) => {
      app.currentUser.logOut();
      state.user = null;
      state.error = null;
      state.status = "logedout";
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.user = null;
      state.status = "loading";
      state.error = null;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      const cookies = new Cookies();
      cookies.set("accessToken", payload.accessToken, {
        path: "/",
        expires: new Date(Date.now() + 60000 * 29),
        secure: true,
      });
      setClientMongoDB();
      state.user = payload;
      state.status = "loggedin";
      state.error = null;
    },
    [loginUser.rejected]: (state, action) => {
      state.user = null;
      state.status = "failed";
      state.error = action?.error?.message;
    },
  },
});

export const { logoutUser } = userSlice.actions;

export default userSlice.reducer;
