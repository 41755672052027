import React from "react";
import Layout from "../components/Layout/Layout";
import { Text, VStack } from "@chakra-ui/react";

function History() {
  return (
    <Layout>
      <VStack marginTop={40}>
        <Text>Bientôt disponible</Text>
      </VStack>
    </Layout>
  );
}

export default History;
