import React from "react";
import { Box, Heading, Grid, GridItem } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { logoutUser } from "../redux/slices/userSlice";
import logout from "./Icons/logout.png";
import { useDispatch } from "react-redux";
import sync from "./Icons/synchronization.png";

function HeaderBar({ synchronize }) {
  const dispatch = useDispatch();
  const location = useLocation();

  let title = null;
  switch (location.pathname) {
    case "/home":
      title = "Accueil";
      break;
    case "/settings":
      title = "Programme";
      break;
    case "/profil":
      title = "Profil";
      break;
    case "/history":
      title = "Historique";
      break;
    default:
      title = "";
      break;
  }
  return (
    <Box
      bg={"secondary"}
      paddingBottom={"5px"}
      paddingTop={"5px"}
      borderBottom={"1px solid"}
      borderBottomColor={"primary.100"}
      position={"fixed"}
      width={"100%"}
      zIndex={"1"}
    >
      <Grid gap={4}>
        <GridItem
          colStart={1}
          colEnd={2}
          style={{ display: "flex", justifyContent: "right" }}
        >
          <img
            src={sync}
            alt="Synchornize"
            width="28px"
            onClick={() => synchronize()}
          />
        </GridItem>
        <GridItem colStart={2} colEnd={11} textAlign="center">
          <Heading fontSize={"xl"}>{title.toUpperCase()}</Heading>
        </GridItem>
        <GridItem colStart={11} colEnd={12}>
          <img
            src={logout}
            onClick={() => dispatch(logoutUser())}
            alt="logout"
            width="25px"
          />
        </GridItem>
      </Grid>
    </Box>
  );
}

export default HeaderBar;
