import { Box, HStack } from "@chakra-ui/react";
import React from "react";
import DayKalories from "../Graphs/DayKalories";
import DayProgress from "../Graphs/DayProgress";
import CardLayout from "../Layout/CardLayout";
import Placeholder from "./Placeholder";
import { toCalories } from "../Algorithms/CalcSchedule";

function TodayConsumption({ todaysData, cat }) {
  return (
    <CardLayout width={"100%"} title={"Consommé aujourd'hui"} divider={true}>
      {todaysData ? (
        <Box>
          <HStack
            // justify={"space-around"}
            justify={"center"}
            align={"center"}
            marginBottom={"20px"}
          >
            <Box>
              <DayKalories
                todaysData={todaysData}
                toCalories={toCalories}
                cat={cat}
              />
            </Box>
            <Box>
              <DayProgress todaysData={todaysData} toCalories={toCalories} />
            </Box>
          </HStack>
        </Box>
      ) : (
        <Placeholder />
      )}
    </CardLayout>
  );
}

export default TodayConsumption;
