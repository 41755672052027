import * as Realm from "realm-web";
import { startOfDay, endOfDay } from "date-fns";
// import React from "react";

// store.subscribe();

export const app = new Realm.App({
  id: process.env.REACT_APP_REALM_APP_ID,
});

export let client = null;

export const loginRealm = async (credentials) => {
  return app.logIn(
    Realm.Credentials.emailPassword(credentials.email, credentials.password)
  );
};

export const setClientMongoDB = async () => {
  client = app.currentUser.mongoClient("mongodb-atlas");
  return;
};

export const loadConfigMongoDB = async () => {
  const targetUser = app.currentUser.id;
  const targetMachine = app.currentUser.customData.serial_number;
  const configCollection = client.db("yopi_trial").collection("config");
  return configCollection.findOne({
    user_id: targetUser,
    serial_number: targetMachine,
  });
};

export const updateConfigMongoDB = async (payload) => {
  const targetMachine = app.currentUser.customData.serial_number;
  const configCollection = client.db("yopi_trial").collection("config");
  return configCollection.updateOne(
    {
      serial_number: targetMachine,
    },
    { $set: { ...payload, timestamp: new Date() } },
    { upsert: true }
  );
};

export const loadTelemetryMongoDB = async () => {
  const targetMachine = app.currentUser.customData.serial_number;
  const telemetryCollection = client.db("yopi_trial").collection("telemetry"); //calling the collection we need
  return telemetryCollection.find(
    {
      user_id: app.currentUser.id,
      serial_number: targetMachine,
      // new Date yesterday
      // timestamp: { $gt: new Date(Date.now() - 24 * 60 * 60 * 1000) },
      timestamp: { $gte: startOfDay(new Date()) },
    },
    { sort: { timestamp: -1 } }
  );
};

export const loadTelemetryYesterdayMongoDB = async () => {
  const targetMachine = app.currentUser.customData.serial_number;
  const telemetryCollection = client.db("yopi_trial").collection("telemetry"); //calling the collection we need
  return telemetryCollection.find(
    {
      user_id: app.currentUser.id,
      serial_number: targetMachine,
      timestamp: {
        $gte: startOfDay(new Date() - 24 * 60 * 60 * 1000),
      },
    },
    { sort: { timestamp: -1 } }
  );
};

export const loadTelemetryLastWeekMongoDB = async () => {
  const targetMachine = app.currentUser.customData.serial_number;
  const telemetryCollection = client.db("yopi_trial").collection("telemetry"); //calling the collection we need
  return telemetryCollection.find(
    {
      user_id: app.currentUser.id,
      serial_number: targetMachine,
      timestamp: {
        $gte: startOfDay(new Date() - 7 * 24 * 60 * 60 * 1000),
      },
    },
    { sort: { timestamp: -1 } }
  );
};

export const calculateDryFoodStockMongoDB = async (payload) => {
  const targetMachine = app.currentUser.customData.serial_number;
  const telemetryCollection = client.db("yopi_trial").collection("telemetry"); //calling the collection we need
  const result = await telemetryCollection.aggregate([
    {
      $match: {
        user_id: app.currentUser.id,
        serial_number: targetMachine,
        timestamp: {
          $gte: payload,
        },
      },
    },
    {
      $group: {
        _id: 1,
        total_dry_delivered: {
          $sum: "$dry_food.distributed_weight",
        },
      },
    },
  ]);
  return result[0]?.total_dry_delivered ? result[0].total_dry_delivered : 0;
};

export const getImagesYesterdayMongoDB = async () => {
  const targetMachine = app.currentUser.customData.serial_number;
  const telemetryCollection = client.db("yopi_trial").collection("telemetry"); //calling the collection we need
  const result = await telemetryCollection.aggregate([
    {
      $match: {
        user_id: app.currentUser.id,
        serial_number: targetMachine,
        timestamp: {
          $gte: startOfDay(new Date() - 24 * 60 * 60 * 1000),
        },
      },
    },
    {
      $unwind: {
        path: "$images",
      },
    },
    {
      $project: {
        images: "$images",
        timestamp: "$timestamp",
      },
    },
  ]);

  return result;
};

export const loadAggregatedDataMongoDB = async () => {
  const targetMachine = app.currentUser.customData.serial_number;
  const aggregatedDataCollection = client
    .db("yopi_trial")
    .collection("daily_agg");

  return await aggregatedDataCollection.findOne(
    {
      timestamp: {
        $gte: startOfDay(new Date()),
        // $lte: endOfDay(new Date()) for some reasons this cause a bug
      },
      serial_number: targetMachine,
    },
    { sort: { timestamp: -1 } }
  );
};

export const updateCatProfileMongoDB = async (payload) => {
  const targetUser = app.currentUser.id;
  const catProfileCollection = client
    .db("yopi_trial")
    .collection("cat_profile");
  try {
    const catProfile = await catProfileCollection.findOne({
      user_id: targetUser,
    });
    if (!catProfile) {
      await catProfileCollection.insertOne({
        user_id: targetUser,
        cat_name: "",
        cat_race: "",
        cat_age: "",
        cat_sex: "",
        cat_weight: "",
        cat_state: "",
        cat_sterilised: "",
        cat_activity: "",
        cat_need: "",
      });
    }
  } catch (error) {
    throw new Error(error.message);
  }
  return catProfileCollection.updateOne(
    {
      user_id: targetUser,
    },
    { $set: payload },
    { upsert: true }
  );
};

export const loadCatProfileMongoDB = async () => {
  const targetUser = app.currentUser.id;
  const catProfileCollection = client
    .db("yopi_trial")
    .collection("cat_profile");
  return await catProfileCollection.findOne({
    user_id: targetUser,
  });
};

export async function monitorListingsUsingEventEmitter(
  timeInMs = 60000,
  pipeline = []
) {
  const targetUser = app.currentUser.id;
  const telemetryCollection = client.db("yopi_trial").collection("telemetry");
  const changeStream = telemetryCollection.watch(pipeline);
  const next = await changeStream.next();
  if (next) {
    return true;
  }
  return false;
}
