import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Box,
  useColorModeValue,
  FormErrorMessage,
  Stack,
  FormControl,
  Input,
  FormLabel,
  NumberInput,
  Link,
  Select,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import {
  states,
  races,
  activities,
  temperaments,
  algorithm,
} from "../Algorithms/Algorithm";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CatModal({ isOpen, onClose, updateCatProfile, cat, loadCatProfile }) {
  const [startDate, setStartDate] = useState(cat ? cat.cat_age : new Date());

  function validateNotEmpty(value) {
    let error;

    if (!value) {
      error = "Required";
    }

    return error;
  }
  const dispatch = useDispatch();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Dites-nous en plus sur votre chat</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={2}>
            <Formik
              initialValues={{
                cat_name: cat?.cat_name ? cat?.cat_name : "",
                cat_age: cat?.cat_age ? cat?.cat_age : "0",
                cat_sex: cat?.cat_sex ? cat?.cat_sex : "",
                cat_weight: cat?.cat_weight ? cat?.cat_weight : "0",
                cat_state: cat?.cat_state ? cat?.cat_state : "",
                cat_sterilised: cat?.cat_sterilised ? cat?.cat_sterilised : "",
                cat_activity: cat?.cat_activity ? cat?.cat_activity : "",
                cat_race: cat?.cat_race ? cat?.cat_race : "",
                cat_temperament: cat?.cat_temperament
                  ? cat?.cat_temperament
                  : "",
                cat_ideal: cat?.cat_ideal ? cat?.cat_ideal : "0",
              }}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  // Default value on creation is 0
                  if (values.cat_age == 0) {
                    values.cat_age = new Date();
                  }
                  dispatch(
                    updateCatProfile({
                      ...values,
                      cat_need: algorithm(values),
                    })
                  );
                  actions.setSubmitting(false);
                }, 200);
              }}
            >
              {(props) => (
                <Form>
                  <Stack spacing={4}>
                    <Field name="cat_name" validate={validateNotEmpty}>
                      {({ field, form }) => (
                        <FormControl
                          id="cat_name"
                          isInvalid={props.errors.cat_name}
                        >
                          <FormLabel>Son nom</FormLabel>
                          <Input {...field} id="cat_name" type="text" />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="cat_age">
                      {({ field, form }) => (
                        <FormControl id="cat_age">
                          <FormLabel>Date de naissance</FormLabel>
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                              height: "2.5rem",
                              outline: "2px solid transparent",
                              outlineOffset: "2px",
                              paddingInlineEnd: "2rem",
                              paddingInlineStart: "1rem",
                              borderRadius: "0.375rem",
                              border: "1px solid",
                              borderColor: "inherit",
                            }}
                          >
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => {
                                setStartDate(date);
                                form.setFieldValue("cat_age", date);
                              }}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          </div>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="cat_race" validate={validateNotEmpty}>
                      {({ field, form }) => (
                        <FormControl
                          id="cat_race"
                          isInvalid={props.errors.cat_race}
                        >
                          <FormLabel>Sa race</FormLabel>
                          <Select
                            {...field}
                            id="cat_race"
                            placeholder="Sélectionnez la race"
                          >
                            {races.map((race) => {
                              return <option value={race}>{race}</option>;
                            })}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="cat_sex" validate={validateNotEmpty}>
                      {({ field, form }) => (
                        <FormControl
                          id="cat_sex"
                          isInvalid={props.errors.cat_sex}
                        >
                          <FormLabel>Son genre</FormLabel>
                          <Select
                            {...field}
                            id="cat_sex"
                            placeholder="Sélectionnez"
                          >
                            <option value="mâle">Mâle</option>
                            <option value="female">Femelle</option>
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="cat_weight"
                      validate={validateNotEmpty}
                      type="number"
                    >
                      {({ field, form }) => (
                        <FormControl id="cat_weight">
                          <FormLabel>Poids en kg</FormLabel>
                          <NumberInput
                            id="cat_weight"
                            precision={2}
                            step={0.1}
                            min={0}
                            format={(value) => field.value.replace(",", ".")}
                            isValidCharacter={(char) =>
                              char.match(/^[Ee0-9+\-.,]$/)
                            }
                          >
                            <NumberInputField
                              id="cat_weight"
                              {...field}
                              placeholder={
                                cat?.cat_weight ? cat?.cat_weight : 0
                              }
                            />
                          </NumberInput>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="cat_state" validate={validateNotEmpty}>
                      {({ field, form }) => (
                        <FormControl
                          id="cat_state"
                          isInvalid={props.errors.cat_state}
                        >
                          <FormLabel>Son état corporel</FormLabel>
                          <Select
                            {...field}
                            id="cat_state"
                            placeholder="Sélectionnez"
                          >
                            {states.map((state) => {
                              return <option value={state}>{state}</option>;
                            })}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="cat_sterilised" validate={validateNotEmpty}>
                      {({ field, form }) => (
                        <FormControl
                          id="cat_sterilised"
                          isInvalid={props.errors.cat_sterilised}
                        >
                          <FormLabel>Est-il stérilisé</FormLabel>
                          <Select
                            {...field}
                            id="cat_sterilised"
                            placeholder="Sélectionnez"
                          >
                            <option value={true}>Oui</option>
                            <option value={false}>Non</option>
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="cat_activity" validate={validateNotEmpty}>
                      {({ field, form }) => (
                        <FormControl
                          id="cat_activity"
                          isInvalid={props.errors.cat_activity}
                        >
                          <FormLabel>Son activité</FormLabel>
                          <Select
                            {...field}
                            id="cat_activity"
                            placeholder="Sélectionnez"
                          >
                            {activities.map((state) => {
                              return <option value={state}>{state}</option>;
                            })}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="cat_temperament" validate={validateNotEmpty}>
                      {({ field, form }) => (
                        <FormControl
                          id="cat_temperament"
                          isInvalid={props.errors.cat_temperament}
                        >
                          <FormLabel>Son tempérament</FormLabel>
                          <Select
                            {...field}
                            id="cat_temperament"
                            placeholder="Sélectionnez"
                          >
                            {temperaments.map((state) => {
                              return <option value={state}>{state}</option>;
                            })}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="cat_ideal"
                      validate={validateNotEmpty}
                      type="number"
                    >
                      {({ field, form }) => (
                        <FormControl id="cat_ideal">
                          <FormLabel>Poids idéal</FormLabel>
                          <NumberInput
                            id="cat_ideal"
                            precision={2}
                            step={0.1}
                            min={0}
                            format={(value) => field.value.replace(",", ".")}
                            isValidCharacter={(char) =>
                              char.match(/^[Ee0-9+\-.,]$/)
                            }
                          >
                            <NumberInputField
                              id="cat_ideal"
                              {...field}
                              placeholder={cat?.cat_ideal ? cat?.cat_ideal : 0}
                            />
                          </NumberInput>
                        </FormControl>
                      )}
                    </Field>
                    <Stack spacing={10}>
                      {!props.isValid ? (
                        <Box
                          border={"1px solid red"}
                          padding={2}
                          textAlign={"center"}
                          rounded={"md"}
                        >
                          <Text color="red.300">
                            Tous les champs sont obligatoires!
                          </Text>
                        </Box>
                      ) : null}
                      <Button
                        isLoading={props.isSubmitting}
                        type="submit"
                        bg={"tertiary"}
                        color={"black"}
                        _hover={{
                          bg: "primary",
                        }}
                        onClick={onClose}
                        isDisabled={
                          !props.isValid ||
                          props.getFieldProps("cat_name").value === ""
                        }
                      >
                        APPLIQUER
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CatModal;
