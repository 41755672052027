import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  loadTelemetryLastWeekMongoDB,
  loadTelemetryMongoDB,
} from "../api/mongodbHandler";

export const loadTelemetry = createAsyncThunk("telemetry/load", async () => {
  return loadTelemetryMongoDB();
});

export const loadTelemetryLastWeek = createAsyncThunk(
  "telemetry/loadWeek",
  async () => {
    return loadTelemetryLastWeekMongoDB();
  }
);

export const telemetrySlice = createSlice({
  name: "telemetry",
  initialState: {
    status: "idle",
    error: null,
    data: null,
  },
  extraReducers: {
    [loadTelemetry.pending]: (state) => {
      state.data = null;
      state.status = "laoding";
      state.error = null;
    },
    [loadTelemetry.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.status = "fetched";
      state.error = null;
    },
    [loadTelemetry.rejected]: (state, action) => {
      state.data = null;
      state.status = "failed";
      state.error = action.error?.message;
    },
    [loadTelemetryLastWeek.pending]: (state) => {
      state.data = null;
      state.status = "laoding";
      state.error = null;
    },
    [loadTelemetryLastWeek.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.status = "old";
      state.error = null;
    },
    [loadTelemetryLastWeek.rejected]: (state, action) => {
      state.data = null;
      state.status = "failed";
      state.error = action.error?.message;
    },
  },
});

export default telemetrySlice.reducer;
