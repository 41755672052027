import React, { useEffect, useMemo } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "./pages/Login";
import { ChakraProvider } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast";
import Home from "./pages/Home";
import { set } from "./redux/slices/clientSlice";

import theme from "./Theme";
import { app, setClientMongoDB } from "./redux/api/mongodbHandler";
import Settings from "./pages/Settings";
import Profil from "./pages/Profil";
import History from "./pages/History";
import ResetPassword from "./pages/ResetPassword";
import Forgot from "./pages/Forgot";
import Cookies from "universal-cookie";
import { hotjar } from "react-hotjar";
import Landing from "./pages/Landing";
import Signup from "./pages/Signup";
import Fonts from "./Fonts";

function App() {
  const state = useSelector((state) => state);
  const user = useMemo(() => {
    return state.user;
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  useEffect(() => {
    console.log(window.location.pathname);
    console.log(user, user?.user);
    if (!user.user) {
      //Check if user is loggedin
      if (
        window.location.pathname.includes("/reset") ||
        window.location.pathname.includes("/forgot") ||
        window.location.pathname.includes("/signup")
      ) {
        return;
      } else {
        navigate("/login");
      }
    } else {
      //Create a client for the app
      setClientMongoDB();
      dispatch(set());
      // navigate("/home");
    }

    if (user?.user && window.location.pathname === "/") {
      navigate("/home");
    }
  }, [user]);

  useEffect(() => {
    hotjar.initialize(2975462, 6);

    if (user.user) {
      app.currentUser.refreshAccessToken();

      cookies.set("accessToken", user.user.accessToken, {
        path: "/",
        expires: new Date(Date.now() + 60000 * 29),
        secure: true,
      });
    }
  }, []);

  console.log(cookies.get("accessToken"));
  console.log(user);

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <AppRouter />
      <Toaster />
    </ChakraProvider>
  );
}

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/profil" element={<Profil />} />
      <Route path="/history" element={<History />} />
      <Route path="/reset" element={<ResetPassword />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/*" element={<Landing />} />
    </Routes>
  );
};

export default App;
