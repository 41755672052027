import React, { useEffect, useState } from "react";
import { Container, Box } from "@chakra-ui/react";
import NavBar from "../NavBar";
import HeaderBar from "../HeaderBar";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import PullToRefresh from "react-simple-pull-to-refresh";
import { loadTelemetry } from "../../redux/slices/telemetrySlice";
import { loadAggregatedData } from "../../redux/slices/aggregatedDataSlice";
import { loadCatProfile } from "../../redux/slices/catProfileSlice";
import { setClientMongoDB } from "../../redux/api/mongodbHandler";
import { loadConfig } from "../../redux/slices/configSlice";
import { loadImages } from "../../redux/slices/imagesSlice";

function Layout({ children }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [noAssignedMachine, setNoAssignedMachine] = useState(false);

  useEffect(() => {
    for (const [key, value] of Object.entries(state)) {
      if (value.error) {
        toast.error(value.error);
      }
    }
  }, [state]);

  const synchronize = async () => {
    //TODO: Exchange dispatch thorugh a subscription to MongoDB and load if a change happend
    if (!noAssignedMachine) {
      console.log("Initializing...");
      setClientMongoDB();
      dispatch(loadImages());
      dispatch(loadTelemetry());
      dispatch(loadAggregatedData());
      dispatch(loadConfig());
      dispatch(loadCatProfile());
      if (state.config.status === "fetched" && state.config.data === null)
        setNoAssignedMachine(true);
    }

    return new Promise(function (resolve, reject) {
      setTimeout(() => resolve(), 1000);
    });
  };

  useEffect(() => {
    synchronize();
  }, []);

  return (
    <Box bg="primary" height="100%" width="100%">
      <HeaderBar synchronize={synchronize} />
      <Container height={"40px"} />
      <PullToRefresh onRefresh={synchronize}>{children}</PullToRefresh>
      <Container height={"75px"} />
      <NavBar />
    </Box>
  );
}

export default Layout;
