import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box, Text, VStack, HStack } from "@chakra-ui/react";
import { toCalories } from "../Algorithms/CalcSchedule";
import { useMemo } from "react";
import { useSelector } from "react-redux";
ChartJS.register(ArcElement, Tooltip, Legend);

function Needed({ schedule, config, cat }) {
  const totalCalories = useMemo(() => {
    let tmp = { wet: 0, dry: 0 };
    //TODO: Hotfix for Dataschema Schedule Rework
    schedule.forEach((s) => {
      if (s.todo === undefined) {
        if (s.active) {
          tmp.wet += toCalories("wet", config.wet_weight_by_dose);
        } else {
          tmp.dry += toCalories("dry", config.dry_weight_by_dose);
        }
      } else {
        s.todo.map((todo) => {
          if (todo === "wet") {
            tmp.wet += toCalories("wet", config.wet_weight_by_dose);
          } else if (todo === "dry") {
            tmp.dry += toCalories("dry", config.dry_weight_by_dose);
          }
        });
      }
    });
    return tmp;
  }, [schedule]);

  const size = "130px";
  const data = {
    labels: ["Scheduled", "Open"],
    datasets: [
      {
        label: "Food",

        data:
          cat.cat_need - totalCalories.wet - totalCalories.dry > 0
            ? [
                totalCalories.dry,
                totalCalories.wet,
                cat.cat_need - totalCalories.wet - totalCalories.dry,
              ]
            : [
                cat.cat_need - totalCalories.wet - totalCalories.dry,
                totalCalories.dry,
                totalCalories.wet,
              ],
        backgroundColor:
          cat.cat_need - totalCalories.wet - totalCalories.dry > 0
            ? ["#FFDED3", "#C6E1FF", "#F9F8F6"]
            : ["#CD4631", "#FFDED3", "#C6E1FF"],
      },
    ],
  };
  const options = {
    cutout: "75%",
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <Box position={"relative"}>
      <Doughnut data={data} options={options} height={size} width={size} />
      <VStack
        margin={"auto"}
        position={"absolute"}
        top={"0"}
        left={"0"}
        right={"0"}
        bottom={"0"}
      >
        <Box
          position={"relative"}
          top={"50%"}
          transform={"translateY(-50%)"}
          maxWidth={"80%"}
        >
          <HStack>
            <Text fontWeight={"bold"} fontSize={"md"}>
              {totalCalories.wet + totalCalories.dry}/
            </Text>
            <Text
              fontWeight={"bold"}
              fontSize={"xs"}
              style={{ marginInlineStart: "0px" }}
            >
              {cat.cat_need}
            </Text>
          </HStack>
          <Text textStyle={"tertiary"} fontSize={"xs"}>
            KCAL
          </Text>
        </Box>
      </VStack>
    </Box>
  );
}

export default Needed;
