import { Button, VStack } from "@chakra-ui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateConfig } from "../../redux/slices/configSlice";

function ApplyButtonSchedule({ calcedSchedule, freeFeedingMode, active }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const applySchedule = async () => {
    await dispatch(
      updateConfig({
        schedules: calcedSchedule.schedule,
        mode: !active ? "free_feeding" : "schedule",
        free_feeding_mode: freeFeedingMode,
      })
    );
    navigate("/settings");
  };

  return (
    <VStack>
      <Button
        bg={"tertiary"}
        onClick={() => applySchedule()}
        textStyle={"tertiary"}
        boxShadow={"rgba(0, 0, 0, 0.1) 0px 4px 12px"}
      >
        APPLIQUER
      </Button>
    </VStack>
  );
}

export default ApplyButtonSchedule;
