import { Box, VStack, Text } from "@chakra-ui/react";
import React from "react";
import CardLayout from "../Layout/CardLayout";
import Placeholder from "./Placeholder";

import CatSad1 from "../Icons/sad_icon1.png";
import CatHappy1 from "../Icons/happy_icon1.png";
import CatSad2 from "../Icons/sad_icon2.png";
import CatHappy2 from "../Icons/happy_icon2.png";
import CatSad3 from "../Icons/sad_icon3.png";
import CatHappy3 from "../Icons/happy_icon3.png";
import CatSad4 from "../Icons/sad_icon4.png";
import CatHappy4 from "../Icons/happy_icon4.png";
import CatSad5 from "../Icons/sad_icon5.png";
import CatHappy5 from "../Icons/happy_icon5.png";
import CatSad6 from "../Icons/sad_icon6.png";
import CatHappy6 from "../Icons/happy_icon6.png";
import CatSad7 from "../Icons/sad_icon7.png";
import CatHappy7 from "../Icons/happy_icon7.png";
import CatSad8 from "../Icons/sad_icon8.png";
import CatHappy8 from "../Icons/happy_icon8.png";
import CatSad9 from "../Icons/sad_icon9.png";
import CatHappy9 from "../Icons/happy_icon9.png";
import CatSad10 from "../Icons/sad_icon10.png";
import CatHappy10 from "../Icons/happy_icon10.png";
import CatSad11 from "../Icons/sad_icon11.png";
import CatHappy11 from "../Icons/happy_icon11.png";
import CatGlouton1 from "../Icons/chat_glouton1.png";
import CatGlouton2 from "../Icons/chat_glouton2.png";
import CatGlouton3 from "../Icons/chat_glouton3.png";
import CatGlouton4 from "../Icons/chat_glouton4.png";
import CatGlouton5 from "../Icons/chat_glouton5.png";
import CatGlouton6 from "../Icons/chat_glouton6.png";
import CatGlouton7 from "../Icons/chat_glouton7.png";
import CatGlouton8 from "../Icons/chat_glouton8.png";
import CatGlouton9 from "../Icons/chat_glouton9.png";
import CatGlouton10 from "../Icons/chat_glouton10.png";
import CatGlouton11 from "../Icons/chat_glouton11.png";

function CatIcon({ icon, height, state }) {
  const styleIcon = {
    height: 80,
  };

  let happy = CatHappy1;
  let glouton = CatGlouton1;
  let sad = CatSad1;
  switch (icon) {
    case "2":
      happy = CatHappy2;
      glouton = CatGlouton2;
      sad = CatSad2;
      break;
    case "3":
      happy = CatHappy3;
      glouton = CatGlouton3;
      sad = CatSad3;
      break;
    case "4":
      happy = CatHappy4;
      glouton = CatGlouton4;
      sad = CatSad4;
      break;
    case "5":
      happy = CatHappy5;
      glouton = CatGlouton5;
      sad = CatSad5;
      break;
    case "6":
      happy = CatHappy6;
      glouton = CatGlouton6;
      sad = CatSad6;
      break;
    case "7":
      happy = CatHappy7;
      glouton = CatGlouton7;
      sad = CatSad7;
      break;
    case "8":
      happy = CatHappy8;
      glouton = CatGlouton8;
      sad = CatSad8;
      break;
    case "9":
      happy = CatHappy9;
      glouton = CatGlouton9;
      sad = CatSad9;
      break;
    case "10":
      happy = CatHappy10;
      glouton = CatGlouton10;
      sad = CatSad10;
      break;
    case "11":
      happy = CatHappy11;
      glouton = CatGlouton11;
      sad = CatSad11;
      break;
    default:
      happy = CatHappy1;
      glouton = CatGlouton1;
      sad = CatSad1;
      break;
  }

  return state === "happy" ? (
    <img src={happy} padding={"4px"} style={styleIcon} alt="happy" />
  ) : state === "glouton" ? (
    <img src={glouton} padding={"4px"} style={styleIcon} alt="glouton" />
  ) : (
    <img src={sad} padding={"4px"} style={styleIcon} alt="sad" />
  );
}

function GeneralStateCat({ lastTelemetry, todaysData, cat }) {
  return (
    <CardLayout width="50%" title={"Etat général"} height={"100%"}>
      <Box>
        {todaysData ? (
          <VStack marginBottom={"10px"} textStyle={"secondary"}>
            {todaysData.missed_food ||
            lastTelemetry?.current_error_msg === "CHAT GLOUTON" ? (
              <VStack>
                <Box paddingLeft={5}>
                  <CatIcon
                    icon={cat?.icon}
                    state={
                      lastTelemetry?.current_error_msg === "CHAT GLOUTON"
                        ? "glouton"
                        : "sad"
                    }
                  />
                </Box>
                <Text textStyle={"tertiary"} fontSize={"xs"}>
                  {lastTelemetry?.current_error_msg === "CHAT GLOUTON"
                    ? "Chat glouton"
                    : `${todaysData.missed_food} 
                  ${
                    todaysData.missed_food > 1
                      ? "portions ratées"
                      : "portion ratée"
                  }`}
                </Text>
              </VStack>
            ) : (
              <VStack>
                <Box paddingLeft={5}>
                  <CatIcon icon={cat?.icon} state={"happy"} />
                </Box>
                <Text textStyle={"tertiary"} fontSize={"xs"}>
                  Tout va bien
                </Text>
              </VStack>
            )}
          </VStack>
        ) : (
          <Placeholder />
        )}
      </Box>
    </CardLayout>
  );
}

export default GeneralStateCat;
