import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  loadCatProfileMongoDB,
  updateCatProfileMongoDB,
} from "../api/mongodbHandler";
import { loadConfig } from "./configSlice";

export const loadCatProfile = createAsyncThunk("catprofile/load", async () => {
  return loadCatProfileMongoDB();
});

export const updateCatProfile = createAsyncThunk(
  "catprofile/update",
  async (payload) => {
    const res = await updateCatProfileMongoDB(payload);
    await loadCatProfile();
    await loadConfig();
    return res;
  }
);

export const catprofileSlice = createSlice({
  name: "catprofile",
  initialState: {
    status: "idle",
    error: null,
    data: null,
  },
  extraReducers: {
    [updateCatProfile.fulfilled]: (state, action) => {
      toast.success("Profil du chat mis à jour");
      state.data = action.meta.arg;
      state.status = "needs_refresh";
    },
    [loadCatProfile.pending]: (state) => {
      state.data = null;
      state.status = "loading";
      state.error = null;
    },
    [loadCatProfile.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.status = "fetched";
      state.error = null;
    },
    [loadCatProfile.rejected]: (state, action) => {
      state.data = null;
      state.status = "failed";
      state.error = action?.error?.message;
    },
  },
});

export default catprofileSlice.reducer;
