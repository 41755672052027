import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import {
  IconButton,
  VStack,
  Text,
  Container,
  Grid,
  GridItem,
  Box,
  Button,
  Icon,
} from "@chakra-ui/react";
import { QuestionIcon, AddIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import ActionButtonModal from "./Modals/ActionButtonModal";

import houseFilled from "./Icons/nav_home_black.svg";
import house from "./Icons/nav_home.svg";
import historyFilled from "./Icons/nav_history_black.svg";
import history from "./Icons/nav_history.svg";
import profilFilled from "./Icons/nav_profile_black.svg";
import profil from "./Icons/nav_profile.svg";
import settingsFilled from "./Icons/nav_settings_black.svg";
import settings from "./Icons/nav_settings.svg";

function NavBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isToBig, setIsToBig] = React.useState(
    window.matchMedia("(min-height: 736px)").matches
  );

  // useEffect(() => {
  //   console.log("toast")

  // }, []);

  return (
    <VStack zIndex={"1"}>
      <Container
        maxWidth={"500px"}
        height={"fit-content"}
        bg="secondary"
        position={"fixed"}
        borderTopLeftRadius={"xl"}
        borderTopRightRadius={"xl"}
        paddingBottom={isToBig ? "10px" : "0px"}
        bottom={"0"}
        width={"100%"}
        paddingTop={"10px"}
        textStyle={"secondary"}
      >
        <Grid
          templateColumns="repeat(5, minmax(0, 1fr))"
          gap={4}
          height={"fit-content"}
        >
          <GridItem
            justifyContent={"center"}
            display={"flex"}
            height={"fit-content"}
            alignContent={"baseline"}
          >
            <NavElement label="Accueil" path="home" />
          </GridItem>
          <GridItem
            justifyContent={"center"}
            display={"flex"}
            height={"fit-content"}
            alignContent={"baseline"}
          >
            <NavElement label="Historique" path="history" />
          </GridItem>
          <GridItem colSpan={1} height={"10px"}>
            <VStack position={"relative"} bottom={"20px"}>
              <IconButton
                _focus={{ boxShadow: "none" }}
                variant={"outlined"}
                color="black"
                bg="tertiary"
                aria-label="add shedule"
                icon={<AddIcon />}
                isRound
                height={"60px"}
                width={"60px"}
                fontSize={"20px"}
                onClick={onOpen}
              />
            </VStack>
          </GridItem>
          <GridItem
            justifyContent={"center"}
            display={"flex"}
            height={"fit-content"}
            alignContent={"baseline"}
          >
            <NavElement label="Profil" path="profil" />
          </GridItem>

          <GridItem
            justifyContent={"center"}
            display={"flex"}
            height={"fit-content"}
            alignContent={"baseline"}
          >
            <NavElement label="Programme" path="settings" />
          </GridItem>
        </Grid>
      </Container>
      <ActionButtonModal isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
}

function NavElement(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const cat = useMemo(() => state.catprofile.data, [state.catprofile.status]);

  return (
    <Box bg="transparent" paddingBottom={"5px"} alignContent={"baseline"}>
      {/* TODO: Add real Icons */}
      {!location.pathname.includes(props.path) ? (
        <Button _focus={{ boxShadow: "none" }} variant={"link"}>
          <VStack
            onClick={() => {
              if (cat === null) {
                toast.error("Vous devez d'abord remplir un profil de chat");
              } else {
                navigate("/" + props.path);
              }
            }}
            width={"100%"}
            height={"100%"}
          >
            {props.path === "home" ? (
              <img src={house} alt="house" />
            ) : props.path === "history" ? (
              <img src={history} alt="history" />
            ) : props.path === "profil" ? (
              <img src={profil} alt="profil" />
            ) : props.path === "settings" ? (
              <img src={settings} alt="settings" />
            ) : (
              <QuestionIcon color="black" width={23} height={23} />
            )}
            <Text fontSize="9px">{props.label}</Text>
          </VStack>
        </Button>
      ) : (
        <VStack width={"100%"}>
          {props.path === "home" ? (
            <img src={houseFilled} alt="house" />
          ) : props.path === "history" ? (
            <img src={historyFilled} alt="history" />
          ) : props.path === "profil" ? (
            <img src={profilFilled} alt="profil" />
          ) : props.path === "settings" ? (
            <img src={settingsFilled} alt="settings" />
          ) : (
            <QuestionOutlineIcon color="black" width={23} height={23} />
          )}
          <CircleIcon boxSize={2} />
        </VStack>
      )}
    </Box>
  );
}

const CircleIcon = (props) => (
  <Icon viewBox="0 0 200 200" {...props}>
    <path
      fill="currentColor"
      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
    />
  </Icon>
);

export default NavBar;
