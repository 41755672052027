import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout/Layout";
import { loadConfig } from "../redux/slices/configSlice";
import { Box, HStack, VStack } from "@chakra-ui/react";
import {
  loadTelemetry,
  loadTelemetryLastWeek,
} from "../redux/slices/telemetrySlice";
import { loadAggregatedData } from "../redux/slices/aggregatedDataSlice";
import { loadCatProfile } from "../redux/slices/catProfileSlice";
import Stock from "../components/Cards/Stock";
import {
  calculateDryFoodStockMongoDB,
  setClientMongoDB,
} from "../redux/api/mongodbHandler";
import Placeholder from "../components/Cards/Placeholder";
import TodayConsumption from "../components/Cards/TodayConsumption";
import GeneralStateCat from "../components/Cards/GeneralStateCat";
import Pictures from "../components/Cards/Pictures";
import TodaySchedule from "../components/Cards/TodaySchedule";
import { loadImages } from "../redux/slices/imagesSlice";

import Loading from "./Loading";
import TodayFreeFeedingHistory from "../components/Cards/TodayFreeFeedingHistory";

export const formattedTime = (timestamp = new Date()) => {
  var date = new Date(timestamp);
  // Hours part from the timestamp
  var hours = date.getHours();
  if (String(hours).length === 1) {
    hours = "0" + hours;
  }
  // Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  var res = hours + ":" + minutes.substr(-2);
  return res;
};

const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export default function Home() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const cat = useMemo(() => state.catprofile.data, [state.catprofile.status]);

  const daily_agg = useMemo(
    () => state.aggregatedData.data,
    [state.aggregatedData.status]
  );

  const telemetry = useMemo(() => {
    const tmp = state.telemetry.data;
    return tmp;
  }, [state.telemetry.status]);

  const config = useMemo(() => {
    const tmp = state.config.data;
    return tmp;
  }, [state.config.status]);

  const images = useMemo(() => {
    const tmp = state.images.data;
    return tmp;
  }, [state.images.status]);

  const [noTelemetry, setNoTelemetry] = useState(false);

  useEffect(() => {
    if (telemetry?.length === 0 && !noAssignedMachine && !noTelemetry) {
      dispatch(loadTelemetryLastWeek());
    }
    if (state.telemetry.status === "old" && telemetry?.length === 0) {
      setNoTelemetry(true);
    }
  }, [state.telemetry.status]);

  const [dryFoodStock, setDryFoodStock] = useState(400);

  useEffect(() => {
    async function getDryFoodStock() {
      if (config) {
        const func = calculateDryFoodStockMongoDB(
          config.dry_food_refill_timestamp
            ? config.dry_food_refill_timestamp
            : new Date()
        );
        const tmp = await func;
        setDryFoodStock(tmp < 0 ? 400 : 400 - tmp);
      }
    }

    if (config) {
      getDryFoodStock();
    }
  }, [state.config.status]);

  const determineMissed = () => {
    let missed = 0;
    telemetry
      .filter((el) => isToday(new Date(el.detection_log?.timestamp)))
      .forEach((item) => {
        if (item.current_error_mode === 1) {
          missed += 1;
        }
      });
    return missed;
  };

  const schedule = useMemo(() => {
    // preferred source of truth = config
    // console.log(config?.schedule, telemetry);

    if (config?.schedules.length > 0) {
      let tmp = [...config.schedules];
      tmp.sort((a, b) => a.time.replace(":", "") - b.time.replace(":", ""));

      if (telemetry && telemetry.length > 0) {
        tmp = tmp.map((slot) => {
          const indexTelemetry = telemetry[0].schedules.findIndex(
            (el) => el.time === slot.time
          );

          if (indexTelemetry !== -1) {
            return {
              ...slot,
              delivered_today:
                telemetry[0].schedules[indexTelemetry]?.delivered_today,
            };
          } else {
            return slot;
          }
        });

        // now let's match doses and errors from telemtry, youhou

        var re = new RegExp("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$");
        const errors = telemetry
          .filter((el) => isToday(new Date(el.detection_log?.timestamp)))
          .filter((el) => el.current_error_mode === 1)
          .map((el) => {
            return {
              msg: el.current_error_msg.substring(0, 5),
              timestamp: el.detection_log,
            };
          });

        errors.forEach((err) => {
          if (re.test(err.msg)) {
            const indexError = tmp.findIndex((el) => el.time === err.msg);
            if (indexError !== -1) {
              tmp[indexError].delivered_today = false;
            }
          }
        });
      }

      return tmp;
    } else if (telemetry && telemetry.length > 0) {
      let tmp = [...telemetry[0].schedules];
      tmp.sort((a, b) => a.time.replace(":", "") - b.time.replace(":", ""));
      return tmp;
    }
  }, [telemetry, config]);

  const determinePlanned = (type) => {
    let planned = 0;
    //TODO: HotFix due to schedule Datastructure switch
    schedule.forEach((item) => {
      if (item.todo === undefined) {
        if (type === "wet" && item.active) {
          planned += config.wet_weight_by_dose;
        } else if (type === "dry" && !item.active) {
          planned += config.dry_weight_by_dose;
        }
      } else {
        if (type === "wet") {
          item.todo.map((todo) => {
            if (todo === "wet") {
              planned += config.wet_weight_by_dose;
            }
          });
        } else if (type === "dry") {
          item.todo.map((todo) => {
            if (todo === "dry") {
              planned += config.dry_weight_by_dose;
            }
          });
        }
      }
    });
    return planned;
  };

  const getImages = (images) => {
    const result = [];
    images.forEach((item) => {
      result.push({
        url: item.images.replace("cloud.google", "googleapis"),
        title: formattedTime(item.timestamp.getTime() - 2 * 60 * 60 * 1000),
      });
    });
    return result;
  };

  const todaysData = useMemo(() => {
    if (config && images) {
      return {
        wet_food_eaten: daily_agg
          ? Math.round(daily_agg?.wet_food_eaten * 100) / 100
          : 0,
        wet_food_planned:
          schedule && config
            ? Math.round(determinePlanned("wet") * 100) / 100
            : 0,
        dry_food_eaten: daily_agg
          ? Math.round(daily_agg?.dry_food_eaten * 100) / 100
          : 0,
        dry_food_planned:
          schedule && config
            ? Math.round(determinePlanned("dry") * 100) / 100
            : 0,
        schedule: schedule,
        missed_food:
          state.telemetry.status == "old"
            ? null
            : telemetry
            ? Math.round(determineMissed() * 100) / 100
            : null,
        images: config ? getImages(images) : [],
        stock_wet:
          telemetry && telemetry.length > 0
            ? telemetry[0].wet_food.number_of_cycle_done
            : 0,
        stock_dry: telemetry && config ? dryFoodStock : 400,
        old: state.telemetry.status == "old" || noTelemetry ? true : false,
      };
    }
  }, [daily_agg, telemetry, schedule, config]);

  const [noAssignedMachine, setNoAssignedMachine] = useState(false);

  useEffect(() => {
    //TODO: Exchange dispatch thorugh a subscription to MongoDB and load if a change happend
    if (!noAssignedMachine) {
      setClientMongoDB();
      dispatch(loadImages());
      dispatch(loadTelemetry());
      dispatch(loadAggregatedData());
      dispatch(loadConfig());
      dispatch(loadCatProfile());
      if (state.config.status === "fetched" && state.config.data === null)
        setNoAssignedMachine(true);
    }
  }, []);

  return (
    <Layout>
      {todaysData ? (
        <VStack>
          <Box maxW={"400px"} width={"95%"}>
            <TodayConsumption todaysData={todaysData} cat={cat} />
            <HStack spacing={0} height="fit-content">
              <GeneralStateCat
                lastTelemetry={telemetry?.[0]}
                todaysData={todaysData}
                cat={cat}
              />
              <Pictures todaysData={todaysData} />
            </HStack>
            {
              config.mode === "schedule" ? (
                <TodaySchedule todaysData={todaysData} config={config} />
              ) : null
              // <TodayFreeFeedingHistory telemetry={telemetry} config={config} />
            }

            <Stock todaysData={todaysData} placeholder={<Placeholder />} />
          </Box>
        </VStack>
      ) : (
        <Loading />
      )}
    </Layout>
  );
}
