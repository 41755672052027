import {
  Box,
  Button,
  HStack,
  VStack,
  Container,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function Landing() {
  const navigate = useNavigate();
  return (
    <Container
      // position={"fixed"}
      width={"100%"}
      maxWidth={"100%"}
      display={"flex"}
      height={"100vh"}
      overflow={"scroll"}
      paddingY={"2rem"}
    >
      <VStack
        // position={"fixed"}
        // top={"20%"}
        // left={"0"}
        // right={"0"}
        spacing={"40px"}
        margin={"auto"}
      >
        <Image src={"/assets/images/yomy_machine.png"} height={"225px"}></Image>
        <Box alignItems={"center"} display={"flex"} flexDirection={"column"}>
          <Text
            fontSize={"2xl"}
            fontWeight={"bold"}
            color={"black"}
            textAlign={"center"}
            maxWidth={"80%"}
          >
            Le compagnon que votre chat attendait.
          </Text>
          <Text
            fontSize={"md"}
            color={"black"}
            textAlign={"center"}
            maxWidth={"80%"}
            textStyle={"secondary"}
          >
            Commencer à programmer les différentes portions de votre chat pour
            qu'il soit en pleine santé.
          </Text>
        </Box>
        <HStack textStyle={"tertiary"}>
          <Button
            width={"100%"}
            bg={"transparent"}
            border={"1px solid black"}
            fontSize={"md"}
            _focus={{ boxShadow: "none" }}
            onClick={() => navigate("/login")}
          >
            SE CONNECTER
          </Button>
        </HStack>
      </VStack>
      <Container
        bg="secondary"
        position="fixed"
        left="-25%"
        top="40%"
        width={"150%"}
        height="100%"
        margin={"10px 0 0 -10px"}
        borderRadius={"500px / 250px"}
        zIndex="-1"
        maxWidth={"150%"}
      />
    </Container>
  );
}

export default Landing;
