import { createSlice } from "@reduxjs/toolkit";
import { app } from "../api/mongodbHandler";

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    client: null,
  },
  reducers: {
    set: (state, action) => {
      state.client = app.currentUser.mongoClient("mongodb-atlas");
    },
  },
});

export const { set } = clientSlice.actions;

export default clientSlice.reducer;
