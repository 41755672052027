import { createSlice } from "@reduxjs/toolkit";
import { getImagesYesterdayMongoDB } from "../api/mongodbHandler";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const loadImages = createAsyncThunk("images/load", async () => {
  return getImagesYesterdayMongoDB();
});

export const imagesSlice = createSlice({
  name: "images",
  initialState: {
    status: "idle",
    error: null,
    data: [],
  },
  extraReducers: {
    [loadImages.pending]: (state) => {
      state.status = "loading";
      state.error = null;
    },
    [loadImages.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.status = "fetched";
      state.error = null;
    },
    [loadImages.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action?.error?.message;
    },
  },
});

export default imagesSlice.reducer;
