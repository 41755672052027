import {
  Box,
  Button,
  HStack,
  Progress,
  Text,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import CardLayout from "../Layout/CardLayout";
import { ReactComponent as Machine } from "../Icons/machine.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../redux/slices/configSlice";
import { ReactComponent as WetFood } from "../Icons/wet_food.svg";
import { ReactComponent as DryFood } from "../Icons/dry_food.svg";

function Stock({ todaysData, Placeholder }) {
  const size = "150px";
  const dispatch = useDispatch();
  const [stock_dry, setStock_dry] = useState(200);

  useEffect(() => {
    if (todaysData) {
      setStock_dry(todaysData.stock_dry);
    }
  });

  //Function to Set Refill Timestamp
  const updateRefillTimestamp = () => {
    dispatch(updateConfig({ dry_food_refill_timestamp: new Date() }));
    setStock_dry(400);
  };

  return (
    <CardLayout title={"État des stocks"} divider={false} width={"100%"}>
      <HStack justifyContent={"space-around"} marginBottom={"10px"}>
        <Machine style={{ margin: "20px" }} />
        {todaysData ? (
          <VStack alignItems={"start"}>
            <Box alignItems={"start"} display={"flex"} flexDirection={"column"}>
              <Text textStyle={"tertiary"} fontSize={"xs"} color={"text"}>
                QUANTITÉ PÂTÉE
              </Text>
              <HStack height={"20px"}>
                <Box width={size}>
                  <ProgressBar
                    completed={Math.round(
                      ((25 - todaysData.stock_wet) / 25) * 100
                    )}
                    labelAlignment="center"
                    bgColor={"var(--chakra-colors-wet_food-main)"}
                    baseBgColor={"var(--chakra-colors-wet_food-50)"}
                    borderRadius={"4px"}
                    labelSize={"10px"}
                    labelColor={"var(--chakra-colors-text)"}
                  />
                </Box>
                <VStack width={"60px"}>
                  <WetFood height={"50px"} fill="white" stroke="#C6E1FF" />
                </VStack>
              </HStack>
            </Box>
            <Box alignItems={"start"} display={"flex"} flexDirection={"column"}>
              <Text textStyle={"tertiary"} fontSize={"xs"} color={"text"}>
                QUANTITÉ CROQUETTES{" "}
              </Text>
              <HStack height={"20px"}>
                <Box width={size} position={"relative"}>
                  <Box position={"absolute"} right={"-10px"} top={"-2px"}>
                    <IconButton
                      _focus={{ boxShadow: "none" }}
                      variant={"outlined"}
                      color="black"
                      bg="tertiary"
                      isRound
                      height={"25px"}
                      minWidth={"25px"}
                      icon={<AddIcon height={"10px"} width={"10px"} />}
                      onClick={() => updateRefillTimestamp()}
                    />
                  </Box>
                  <ProgressBar
                    completed={Math.round((stock_dry / 400) * 100 * 100) / 100}
                    labelAlignment="center"
                    bgColor={"var(--chakra-colors-dry_food-main)"}
                    baseBgColor={"var(--chakra-colors-dry_food-50)"}
                    borderRadius={"4px"}
                    labelSize={"10px"}
                    labelColor={"var(--chakra-colors-text)"}
                  />
                </Box>
                <HStack>
                  <DryFood height={"25px"} fill="#FFDED3" stroke="#FFDED3" />
                </HStack>
              </HStack>
            </Box>
          </VStack>
        ) : (
          <Box>{Placeholder}</Box>
        )}
        {/* <Button onClick={() => updateRefillTimestamp()}></Button> */}
      </HStack>
    </CardLayout>
  );
}

export default Stock;
